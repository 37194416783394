import {call, put, takeLatest, delay, select} from 'redux-saga/effects' // Import debounce from redux-saga
import {
  getDanhSachCoSoKCBBaoLanh,
  getDanhSachCoSoKCBKTTBH,
} from './service'
import {handleError} from '_metronic/helpers'
import {selectIdHopDong} from './contractStepTwoSlice'
import {contractStepTwoActions} from './contractStepTwoSlice'

function* debounceSearchDanhSachCoSoKCBBL(action) {
  yield delay(500) // Đợi 300ms trước khi thực hiện tìm kiếm
  yield call(handleGetDanhSachCoSoKCBBL, {payload: action.payload})
}
function* debounceSearchDanhSachCoSoKCBKTTBH(action) {
  yield delay(500) // Đợi 300ms trước khi thực hiện tìm kiếm
  yield call(handleGetDanhSachCoSoKCBKTTBH, {payload: action.payload})
}
// get danh sách hợp đồng
function* handleGetDanhSachCoSoKCBBL({payload}) {
  const idHopDong = yield select(selectIdHopDong)
  try {
    const resultDanhSachCoSoKCBBL = yield call(getDanhSachCoSoKCBBaoLanh, {
      data: payload,
      idHopDong,
    })
    if (resultDanhSachCoSoKCBBL) {
      yield put({
        type: contractStepTwoActions.getDanhSachCoSoKCBBLSuccess.type,
        payload: resultDanhSachCoSoKCBBL,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepTwoActions.getDanhSachCoSoKCBBLFailed.type,
    })
    handleError(e)
  }
}

function* handleGetDanhSachCoSoKCBKTTBH({payload}) {
  // console.log('payload', payload)
  const idHopDong = yield select(selectIdHopDong)
  try {
    const resultDanhSachCoSoKCBKTTBH = yield call(getDanhSachCoSoKCBKTTBH, {
      data: payload,
      idHopDong,
    })
    // console.log(resultDanhSachCoSoKCBKTTBH)
    if (resultDanhSachCoSoKCBKTTBH) {
      yield put({
        type: contractStepTwoActions.getDanhSachCoSoKCBKTTBHSuccess.type,
        payload: resultDanhSachCoSoKCBKTTBH,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepTwoActions.getDanhSachCoSoKCBKTTBHFailed.type,
    })
    handleError(e)
  }
}
export default function* contractStepTwoSaga() {
  yield takeLatest(
    contractStepTwoActions.getDanhSachCoSoKCBBL.type,
    debounceSearchDanhSachCoSoKCBBL
  )
  yield takeLatest(
    contractStepTwoActions.getDanhSachCoSoKCBKTTBH.type,
    debounceSearchDanhSachCoSoKCBKTTBH
  )
}
