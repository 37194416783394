import {FC, useEffect, useState} from 'react'
import {selectPageTitle} from '../../../../../app/redux/common/commonSlice'
import {useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import PopupWarning from 'app/pages/popup/PopupWarning'

const ToolbarAccounting: FC = () => {
  const title = useSelector(selectPageTitle)
  const location = useLocation()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const onHide = () => setShow(!show)
  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])

  const onBackbtn = () => {
    navigate('/tham-dinh-chu-dong/danh-sach-hskcb')
  }

  return (
    <>
      <div className='d-flex align-items-center me-5 justify-content-between'>
        <h2 className='mt-2' style={{fontSize: '20px', lineHeight: '28px', fontWeight: 600}}>
          {title}
        </h2>
        {location?.pathname?.includes('chi-tiet-hskcb') && (
          <div
            onClick={() => {
              setShow(!show)
            }}
            className='btn btn-sm btn-secondary d-flex justify-conten-center align-items-center'
          >
            <img src={'/media/common/reply.svg'} style={{marginRight: '5px'}} />
            Quay về
          </div>
        )}
        <PopupWarning
          show={show}
          onHide={() => {
            onHide()
          }}
          title='Quay về màn danh sách'
          subTitle='Kết quả thẩm định của hồ sơ hiện tại chưa được lưu lại'
          textAgree='Chuyển'
          onAgree={() => {
            onBackbtn()
          }}
        />
      </div>
    </>
  )
}

export {ToolbarAccounting}
