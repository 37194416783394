import {call, put, takeLatest, select} from 'redux-saga/effects' // Import debounce from redux-saga
import {
  getDanhSachKenhKhaiThacQuaDaiLy,
  getDanhSachKenhKhaiThacQuaMoiGioi,
  getDanhSachKenhKhaiThacTrucTiep,
  getDanhSachPhuongThucBoiThuongBenThuBa,
  getListHopDongBaoHiem,
  getListKhachHang,
  getListSanPhamBaoHiem,
} from './service'
import {handleError} from '_metronic/helpers'
import {contractStepOneActions, selectIdDonVi} from './contractStepOneSlice'

function* handleGetDanhSachKenhKhaiThacQuaDaiLy({payload}) {
  try {
    const result = yield call(getDanhSachKenhKhaiThacQuaDaiLy, {
      showAll: payload,
    })
    if (result) {
      yield put({
        type: contractStepOneActions.getListKenhKhaiThacQuaDaiLySuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepOneActions.getListKenhKhaiThacQuaDaiLyFailed.type,
    })
    handleError(e)
  }
}

function* handleGetDanhSachKenhKhaiThacQuaMoiGioi({payload}) {
  try {
    const result = yield call(getDanhSachKenhKhaiThacQuaMoiGioi, {
      showAll: payload,
    })
    if (result) {
      yield put({
        type: contractStepOneActions.getListKenhKhaiThacQuaMoiGioiSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepOneActions.getListKenhKhaiThacQuaMoiGioiFailed.type,
    })
    handleError(e)
  }
}

function* handleGetDanhSachThongTinSanPham({payload}) {
  try {
    const result = yield call(getListSanPhamBaoHiem, payload)
    if (result) {
      yield put({
        type: contractStepOneActions.getListThongTinSanPhamSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepOneActions.getListThongTinSanPhamFailed.type,
    })
    handleError(e)
  }
}

function* handleGetDanhSachLoaiKhachHang({payload}) {
  try {
    const result = yield call(getListKhachHang, payload)
    if (result) {
      yield put({
        type: contractStepOneActions.getListLoaiKhachHangSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepOneActions.getListLoaiKhachHangFailed.type,
    })
    handleError(e)
  }
}

function* handleGetDanhSachLoaiHopDongBaoHiem({payload}) {
  try {
    const result = yield call(getListHopDongBaoHiem, payload)
    if (result) {
      yield put({
        type: contractStepOneActions.getListLoaiHopDongBaoHiemSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepOneActions.getListLoaiHopDongBaoHiemFailed.type,
    })
    handleError(e)
  }
}

function* handleGetDanhSachPhuongThucBoiThuongBenThuBa({payload}) {
  try {
    const result = yield call(getDanhSachPhuongThucBoiThuongBenThuBa, payload)
    if (result) {
      yield put({
        type: contractStepOneActions.getListPhuongThucBoiThuongBenThuBaSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepOneActions.getListPhuongThucBoiThuongBenThuBaFailed.type,
    })
    handleError(e)
  }
}

function* handleGetDanhSachKenhKhacTrucTiep({payload}) {
  const idDonVi = yield select(selectIdDonVi)
  try {
    const result = yield call(getDanhSachKenhKhaiThacTrucTiep, {
      showAll: payload,
      idDonVi,
    })
    if (result) {
      yield put({
        type: contractStepOneActions.getListKenhKhaiThacTrucTiepSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepOneActions.getListPhuongThucBoiThuongBenThuBaFailed.type,
    })
    handleError(e)
  }
}

export default function* contractStepOneSaga() {
  yield takeLatest(
    contractStepOneActions.getListKenhKhaiThacQuaDaiLy.type,
    handleGetDanhSachKenhKhaiThacQuaDaiLy
  )
  yield takeLatest(
    contractStepOneActions.getListKenhKhaiThacQuaMoiGioi.type,
    handleGetDanhSachKenhKhaiThacQuaMoiGioi
  )
  yield takeLatest(
    contractStepOneActions.getListThongTinSanPham.type,
    handleGetDanhSachThongTinSanPham
  )
  yield takeLatest(contractStepOneActions.getListLoaiKhachHang.type, handleGetDanhSachLoaiKhachHang)
  yield takeLatest(
    contractStepOneActions.getListLoaiHopDongBaoHiem.type,
    handleGetDanhSachLoaiHopDongBaoHiem
  )
  yield takeLatest(
    contractStepOneActions.getListPhuongThucBoiThuongBenThuBa.type,
    handleGetDanhSachPhuongThucBoiThuongBenThuBa
  )
  yield takeLatest(
    contractStepOneActions.getListKenhKhaiThacTrucTiep.type,
    handleGetDanhSachKenhKhacTrucTiep
  )
}
