const errorCodeVariables: any = {
    SUCCESS: "Success",
    UNKNOWN: "Lỗi không xác định",
    UNAUTHORIZED:"Không có quyền truy cập dữ liệu, vui lòng liên hệ quản trị hệ thống",
    IF01: "Import dữ liệu thất bại",
    DE_TT_QG: "Xóa thông tin danh mục thất bại quốc gia đang được chọn tại tỉnh thành",
    DE_QH_TT: "Xóa thông tin danh mục thất bại tỉnh thành đang được chọn tại quận huyện",
    DE_XP_TT: "Xóa thông tin danh mục thất bại tỉnh thành đang được chọn tại phường xã",
    DE_XP_QH: "Xóa thông tin danh mục thất bại quận huyện đang được chọn tại Phường xã"
}

export {
    errorCodeVariables
}