import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {useSelector} from 'react-redux'
import {selectListPermissionsMenu} from 'app/redux/permissions/permissionsSlice'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  keyPer?: string
}

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  keyPer,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const listPermissionMenu: any = useSelector(selectListPermissionsMenu)

  function containsPermission(key: any) {
    for (const permission of listPermissionMenu) {
      if (permission.includes(`${key}.`)) {
        return true
      }
    }
    return false
  }
  if (containsPermission(keyPer)) {
    return (
      <div
        className={clsx('menu-item', 'menu-accordion')}
        data-kt-menu-trigger='click'
      >
        <span className='menu-link' style={{backgroundColor: 'rgb(242, 245, 248)', position: 'sticky', top: '0'}}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              {/* <i className={`${icon} fs-2`}></i> */}
              {/* <KTIcon iconName={icon} className='fs-2' /> */}
              <img src={icon} />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span className='menu-title'>{title}</span>
          <span className='menu-arrow'></span>
        </span>
        <div className={clsx('menu-sub menu-sub-accordion ', {'menu-active-bg': isActive})}>
          {children}
        </div>
      </div>
    )
  }
}

export {SidebarMenuItemWithSub}
