import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  typeMedicalExpense : null,
  listManualAssessment: [],
  loadingTypeMedicalExpense : false,
  listWarningManualAssessment: []
}

export const manualAssessmentSlice = createSlice({
  name: 'manualAssessment',
  initialState,
  reducers: {
    // getTypeMedicalExpense(state, action) {
    //   state.loadingTypeMedicalExpense = true
    // },
    // setTypeMedicalExpense(state, action) {
    //   state.typeMedicalExpense = action.payload.type
    //   state.listManualAssessment = action.payload.listSubRowsArray
    //   state.loadingTypeMedicalExpense = false
    // },
    // setListManualAssessment(state, action) {
    //   state.listManualAssessment = action.payload
    // },
    getListWarningManualAssessment(state,action){
      state.loadingTypeMedicalExpense = true
    },
    getListWarningManualAssessmentSuccess(state,action){
      state.loadingTypeMedicalExpense = false
      state.typeMedicalExpense = action.payload.type
      state.listWarningManualAssessment = action.payload.resultsListManualAssessment
    },
    getListWarningManualAssessmentFailed(state){
      state.loadingTypeMedicalExpense = false
      state.typeMedicalExpense = null
      state.listWarningManualAssessment = []
    }
  }, 
})
export const manualAssessmentActions = manualAssessmentSlice.actions
const manualAssessmentReducer = manualAssessmentSlice.reducer

export default manualAssessmentReducer

export const selectListManualAssessment = (state: any) => state.manualAssessment.listManualAssessment
export const selectTypeMedicalExpense = (state: any) => state.manualAssessment.typeMedicalExpense
export const selectListWarningManualAssessment = (state: any) => state.manualAssessment.listWarningManualAssessment




