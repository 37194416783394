import {api} from 'app/config/api'
import axios from 'axios'
import moment from 'moment'

export const getListMedicalRecord = (data) => {
  const {typeTGRV, ngayVao, ngayRa, mot_ngay, reload, ...otherData} = data
  // console.log("otherData",otherData);
  const objectTemp = {
    ...otherData,
    idLanhDao: otherData?.idLanhDao?.value,
    page: otherData?.page / otherData?.items_per_page,
    kyQt: otherData.kyQt ? Number(moment(otherData.kyQt).format('YYYYMM')) : null,
    donviBHSK: otherData?.donviBHSK?.value,
    donviTD: otherData?.donviTD?.value,
    maCSKCB: otherData?.maCSKCB?.value,
    trangThaiHs: otherData?.trangThaiHs?.map((e) => e.value),
    thoiGianRa: ngayRa ? otherData?.thoiGianRa : null,
    thoiGianVao: ngayVao ? otherData?.thoiGianVao : null,
    tienDuyet: [
      otherData?.tienDuyet[0] == null ? -1 : otherData?.tienDuyet[0],
      otherData?.tienDuyet[1] == null ? -1 : otherData?.tienDuyet[1],
    ],
    tongThanhToan: [
      otherData?.tongThanhToan[0] == null ? -1 : otherData?.tongThanhToan[0],
      otherData?.tongThanhToan[1] == null ? -1 : otherData?.tongThanhToan[1],
    ],
  }
  removeNullFieldsMedicalRecord(objectTemp)
  return new Promise((resolve, reject) => {
    axios
      .post(api.API_HOSO_KCB, objectTemp)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListDirectPaymentRecord = (data) => {
  const {
    typeTGRV,
    dsDonViBHSK,
    dsDonViThamDinh,
    ngayVao,
    ngayRa,
    mot_ngay,
    reload,
    selectedLanhDao,
    ...otherData
  } = data
  console.log(otherData, 'otherData')
  const objectTemp = {
    ...otherData,
    page: otherData?.page / otherData?.items_per_page,
    trangThaiHoSo: otherData?.trangThaiHoSo?.map((e) => e.value),
    phanLoaiKCB:
      Array.isArray(otherData?.phanLoaiKCB) &&
      typeof otherData?.phanLoaiKCB[0] === 'object' &&
      otherData?.phanLoaiKCB[0]?.hasOwnProperty('label') &&
      otherData?.phanLoaiKCB[0]?.hasOwnProperty('value')
        ? otherData?.phanLoaiKCB.some((val) => val?.value === null) ||
          otherData?.phanLoaiKCB.some((val) => val?.value === undefined)
          ? null
          : otherData?.phanLoaiKCB?.map((val) => val?.value)
        : otherData?.phanLoaiKCB,
    loaiHoSoHoanTra:
      Array.isArray(otherData.loaiHoSoHoanTra) && otherData.loaiHoSoHoanTra.length === 0
        ? null
        : Array.isArray(otherData.loaiHoSoHoanTra) &&
          typeof otherData.loaiHoSoHoanTra[0] === 'number'
        ? otherData.loaiHoSoHoanTra
        : otherData.loaiHoSoHoanTra?.map((item) => item.value),
  }
  console.log(data, 'dsdserrvice')
  const filteredObject = removeNullFields(objectTemp)
  if (
    filteredObject.thoiGianRaVao &&
    Array.isArray(filteredObject.thoiGianRaVao) &&
    filteredObject.thoiGianRaVao.length === 2 &&
    filteredObject.thoiGianRaVao[0] === 0 &&
    filteredObject.thoiGianRaVao[1] === 0
  ) {
    delete filteredObject.thoiGianRaVao
  }
  if (
    filteredObject.tongThanhToan &&
    Array.isArray(filteredObject.tongThanhToan) &&
    filteredObject.tongThanhToan.length === 2 &&
    filteredObject.tongThanhToan[0] === 0 &&
    filteredObject.tongThanhToan[1] === -1
  ) {
    delete filteredObject.tongThanhToan
  }
  if (
    filteredObject.tongTienDuyet &&
    Array.isArray(filteredObject.tongTienDuyet) &&
    filteredObject.tongTienDuyet.length === 2 &&
    filteredObject.tongTienDuyet[0] === 0 &&
    filteredObject.tongTienDuyet[1] === -1
  ) {
    delete filteredObject.tongTienDuyet
  }
  if (
    filteredObject.tongVienPhi &&
    Array.isArray(filteredObject.tongVienPhi) &&
    filteredObject.tongVienPhi.length === 2 &&
    filteredObject.tongVienPhi[0] === 0 &&
    filteredObject.tongVienPhi[1] === -1
  ) {
    delete filteredObject.tongVienPhi
  }
  // console.log('objectTemp', filteredObject)
  const objString = '?' + new URLSearchParams(filteredObject).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_HOSO_TTTT}${objString}`)
      .then((results) => {
        console.log(results)
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function removeNullFields(obj) {
  const filteredObj = {}
  for (const key in obj) {
    if (
      obj[key] !== null &&
      obj[key] !== 'null' &&
      obj[key] !== '' &&
      obj[key] !== 'all' &&
      obj[key] !== undefined
    ) {
      filteredObj[key] = obj[key]
    }
  }
  return filteredObj
}

function removeNullFieldsMedicalRecord(obj) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === 'null' || obj[key] === '' || obj[key]?.length === 0) {
      delete obj[key]
    }
  }
}

export const postMedicalExpense = (results) => {
  // console.log('dataaaaa', results)

  return new Promise((resolve, reject) => {
    axios
      .post(
        `${api.API_DETAILED_MEDICAL_RECORD_MEDICAL_EXPENSE_V2}${results?.idHoSo}/actions/tham-dinh`,
        results?.data
      )
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
