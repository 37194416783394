import {api} from 'app/config/api'
import axios from 'axios'

export const getListContract = (data) => {
  const { ...otherData} = data
  const objectTemp = {
    ...otherData,
    page: otherData?.page / otherData?.items_per_page,
    typeKenhKhaiThac :otherData?.typeKenhKhaiThac?.value,
    typePhuongThucBoiThuong :otherData?.typePhuongThucBoiThuong?.value
  }
  removeNullFieldsMedicalRecord(objectTemp)
  const objString = '?' + new URLSearchParams(objectTemp).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_CONTRACT + objString)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}


function removeNullFieldsMedicalRecord(obj) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === 'null' || obj[key] === '' || obj[key]?.length === 0 || obj[key] === 'undefined' || obj[key] === undefined) {
      delete obj[key]
    }
  }
}