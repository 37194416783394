import './style.css'
import {useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {commonActions, selectMenuItem} from 'app/redux/common/commonSlice'
import {useSelector} from 'react-redux'
import {useAuth} from 'app/pages/auth'
import { selectListPermissionsMenu } from 'app/redux/permissions/permissionsSlice'
import { useNavigate } from 'react-router-dom'

export function MenuInner() {
  const menuItem = useSelector(selectMenuItem)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {currentUser} = useAuth()
  const {roles} = currentUser?.realm
  const listPermissionMenu: any = useSelector(selectListPermissionsMenu)
  const renderMenuNgang = () => {
    const menuItems = [
      { key: 'tracuu', label: 'Tra cứu' },    
      { key: 'hopdong', label: 'Quản lý hợp đồng' },    
      { key: 'thietkesanpham', label: 'Thiết kế sản phẩm' },
      { key: 'thamdinhtudong', label: 'Thẩm định tự động' },
      { key: 'thamdinhchudong', label: 'Thẩm định chủ động' },
      // { key: 'thanhtoan', label: 'Thanh toán' },
      { key: 'thanhquyettoan', label: 'Thanh toán' },
      { key: 'quyettoan', label: 'Quyết toán'},
      { key: 'Dashbroad', label: 'Dashbroad' },
      { key: 'tienichtrogiup', label: 'Tiện ích trợ giúp' },
      { key: 'thongkebaocao', label: 'Thống kê báo cáo' },
      { key: 'quantrihethong', label: 'Quản trị hệ thống' },
      { key: 'danhmuc', label: 'Danh mục' },
      { key: 'trogiup', label: ' Trợ giúp' },
    ];

    function containsPermission(key :any) {
      for (const permission of listPermissionMenu) {
        const index = permission?.indexOf('.');
        if (index !== -1 && permission?.substring(0, index) === key) {
          return true;
        }
        // if (permission.includes(`${key}.`)) {
        //   return true;
        // }
      }
      return false;
    } 

    return (
      <div
        className='menu_item'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '48px',
          cursor: 'pointer',
        }}
      >
        <div onClick={() => navigate('apps/account/overview')} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px'}}>
          {/* <img src={'/media/common/logo_azinsu.svg'}  /> */}
          <img style={{width:180}} src={'/media/common/logo_with_black_bg.svg'}  />
          {/* <img src={'/media/common/DInsurance.svg'} /> */}
        </div>

        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '24px'}}>
        {menuItems.map(item => {
          if(containsPermission(item.key)){
            return (
              <div
                key={item.key}
                onClick={() => {
                  dispatch(commonActions.setMenuItem(item.key))
                }}
                style={{
                  color: menuItem === item.key  ? '#ffffff' : '#d7dbdf',
                  fontWeight: menuItem === item.key ? '600' : 'initial',
                  lineHeight: '22px',
                  fontSize: 14,
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {item.label}
              </div>
            )
          }
        })}
        </div>
      </div>
    )
  }
  const memoRenderMenuNgang = useMemo(() => renderMenuNgang(), [roles, menuItem,listPermissionMenu])
  return <>{memoRenderMenuNgang}</>
}
