import { call, put, takeEvery } from "redux-saga/effects";
import { traCuuActions } from "./traCuuSlice";
import { handleError } from "_metronic/helpers";
import { getListTraCuuNDBH } from "./service";
import ToastMessage from "app/components/ToastMessage";


function* handleGetListTraCuuNDBH({ payload }) {
  try {
    const resultsListTraCuuNDBH = yield call(getListTraCuuNDBH, payload?.objectSearch);
    if (resultsListTraCuuNDBH?.result) {
      if (resultsListTraCuuNDBH?.result?.length === 0) {
        ToastMessage('warning', 'Không tìm thấy thông tin tìm kiếm')
    }
    if (resultsListTraCuuNDBH?.result) {
      yield put({
        type: traCuuActions.getListTraCuuNDBHSuccess.type,
        payload : resultsListTraCuuNDBH?.result,
      });
      if(payload?.selectNDBH && resultsListTraCuuNDBH?.result?.[0]){
        yield put({
          type: traCuuActions.setChiTietNDBH.type,
          payload: resultsListTraCuuNDBH?.result?.[0],
        });
      }else{
        yield put({
          type: traCuuActions.setVisiblePopupSelectNDBH.type,
          payload: true,
        });
      }
    }
    }
  } catch (e) {
    yield put({
      type: traCuuActions.getListTraCuuNDBHFailed.type,
    });
    handleError(e)
  }
}


export default function* traCuuSaga() {
  yield takeEvery(traCuuActions.getListTraCuuNDBH.type, handleGetListTraCuuNDBH);
}
