import { call, put, takeEvery, select } from "redux-saga/effects";
import { contractActions, selectListLevelContract } from "./contractSlice";
import { handleError } from "_metronic/helpers";
import { getContractDetail, getContractDetailHSKCB,getContractDetailHSTTTT , getContractParentsHSKCBHSTTTT, getContractParentsStep5, getListChildrenByIds, getListLevelContract, getListUnitCal, getListUnitTime } from "./service";

function* handleGetContractDetail({ payload }) {
  try {
    const resultsContractDetail = yield call(getContractDetail, payload);
    // const resultsContractDetail = detailDataTemp
    if(resultsContractDetail?.result?.stageHopDong){
      yield put({
        type: contractActions.setStageHopDong.type,
        payload: resultsContractDetail?.result?.stageHopDong,
      })
    }
    if (resultsContractDetail?.result?.dsDieuKhoanBaoHiem) {
      const result = resultsContractDetail?.result?.dsDieuKhoanBaoHiem
      const sortListContractDetail = result.sort((a, b) => a.viewOrder - b.viewOrder);
      const updatedDetailData = addDsHanMucDieuKienToChildren(sortListContractDetail);
      const allHanMucDieuKien = getAllHanMucDieuKien(updatedDetailData);
      let ids = result.map((item) => item.idQuyenLoiBaoHiem)
      yield put({
        type: contractActions.setCheckedIdParents.type,
        payload: ids,
      });
      yield put({
        type: contractActions.setListAllDanhSachHanMuc.type,
        payload: allHanMucDieuKien,
      });
      yield put({
        type: contractActions.getListContractDetailSuccess.type,
        payload: updatedDetailData,
      });
    }
  } catch (e) {
    yield put({
      type: contractActions.getListContractDetailFailed.type,
    });
    handleError(e)
  }
}

function* handleGetContractDetailHSKCB({ payload }) {
  // const dsHanMucDieuKien = yield select(selectListLevelContract);
  // const firstItemUnitCal = yield select(selectFirstItemUnitCal);
  try {
    const resultsContractDetail = yield call(getContractDetailHSKCB, payload);
    if (resultsContractDetail?.result) {
      const result = resultsContractDetail?.result
      const sortListContractDetail = result.sort((a, b) => a.viewOrder - b.viewOrder);
      const updatedDetailData = addDsHanMucDieuKienToChildren(sortListContractDetail);
      const allHanMucDieuKien = getAllHanMucDieuKien(updatedDetailData);
      let ids = result.map((item) => item.idQuyenLoiBaoHiem)
      yield put({
        type: contractActions.setCheckedIdParents.type,
        payload: ids,
      });
      yield put({
        type: contractActions.setListAllDanhSachHanMuc.type,
        payload: allHanMucDieuKien,
      });
      yield put({
        type: contractActions.getListContractDetailSuccess.type,
        payload: updatedDetailData,
      });
    }
  } catch (e) {
    yield put({
      type: contractActions.getListContractDetailFailed.type,
    });
    handleError(e)
  }
}
function* handleGetContractDetailHSTTT({ payload }) {
  // const dsHanMucDieuKien = yield select(selectListLevelContract);
  // const firstItemUnitCal = yield select(selectFirstItemUnitCal);
  try {
    const resultsContractDetail = yield call(getContractDetailHSTTTT, payload);
    if (resultsContractDetail?.result) {
      const result = resultsContractDetail?.result
      const sortListContractDetail = result.sort((a, b) => a.viewOrder - b.viewOrder);
      const updatedDetailData = addDsHanMucDieuKienToChildren(sortListContractDetail);
      const allHanMucDieuKien = getAllHanMucDieuKien(updatedDetailData);
      let ids = result.map((item) => item.idQuyenLoiBaoHiem)
      yield put({
        type: contractActions.setCheckedIdParents.type,
        payload: ids,
      });
      yield put({
        type: contractActions.setListAllDanhSachHanMuc.type,
        payload: allHanMucDieuKien,
      });
      yield put({
        type: contractActions.getListContractDetailSuccess.type,
        payload: updatedDetailData,
      });
    }
  } catch (e) {
    yield put({
      type: contractActions.getListContractDetailFailed.type,
    });
    handleError(e)
  }
}
function* handleGetListLevelContract({ payload }) {
  try {
    const resultsListLevelContract = yield call(getListLevelContract, payload);
    if (resultsListLevelContract?.result) {
      yield put({
        type: contractActions.getListLevelContractSuccess.type,
        payload: resultsListLevelContract?.result,
      });
    }
  } catch (e) {
    yield put({
      type: contractActions.getListLevelContractFailed.type,
    });
    handleError(e)
  }
}

function* handleGetListUnitCal({ payload }) {
  try {
    const resultsListUnitCal = yield call(getListUnitCal, payload);
    if (resultsListUnitCal) {
      const resultsListUnitCalTemp = resultsListUnitCal?.map((element) => { return ({ ...element, idDonViTinh: element?.value, tenDonViTinh: element?.label  }) })
      yield put({
        type: contractActions.getListUnitCalSuccess.type,
        payload: resultsListUnitCalTemp
      });
      yield put({
        type: contractActions.setFirstItemUnitCal.type,
        payload: resultsListUnitCalTemp[0]
      });
    }
  } catch (e) {
    yield put({
      type: contractActions.getListUnitCalFailed.type,
    });
    handleError(e)
  }
}

function* handleGetListUnitTime({ payload }) {
  try {
    const resultsListUnitTime = yield call(getListUnitTime, payload);
    if (resultsListUnitTime?.result) {
      const resultsListUnitTimeTemp = resultsListUnitTime?.result?.map((element) => { return ({ ...element, idDonViThoiGian: element?.value, tenDonViThoiGian: element?.label  }) })
      yield put({
        type: contractActions.getListUnitTimeSuccess.type,
        payload: resultsListUnitTimeTemp
      });
    }
  } catch (e) {
    yield put({
      type: contractActions.getListUnitTimeFailed.type,
    });
    handleError(e)
  }
}

function* handleGetListContractParents({ payload  }) {
  try {
    let resultsContractParents;
    if(payload.type === "step5"){
      resultsContractParents = yield call(getContractParentsStep5, payload);
    }else{
      resultsContractParents = yield call(getContractParentsHSKCBHSTTTT, payload);
    }
    if (resultsContractParents?.result) {
      const dataParentsTemp = resultsContractParents?.result
      yield put({
        type: contractActions.getListContractParentsSuccess.type,
        payload: dataParentsTemp,
      });
    }
  } catch (e) {
    yield put({
      type: contractActions.getListContractParentsFailed.type,
    });
    // handleError(e)
  }
}

function* handleGetListChildrenByIds({ payload }) {
  console.log("payload",payload);
  const dsHanMucDieuKien = yield select(selectListLevelContract);
  switch (payload?.typeAction) {
    case "select-option":
      const newItemSelect = [payload.dataAddRemove.value]
      try {
        const listChildrenByIds = yield call(getListChildrenByIds, newItemSelect);
        if (listChildrenByIds?.result) {
          const dataChildrenTemp = listChildrenByIds?.result;
          const dataDetail = payload?.data;
          const updatedDataDetail = addToParentChildren(dataDetail, dataChildrenTemp);
          const updatedDetailData = addDsHanMucDieuKienToChildren(updatedDataDetail, dsHanMucDieuKien);
          const allHanMucDieuKien = getAllHanMucDieuKien(updatedDetailData);
          yield put({
            type: contractActions.setListAllDanhSachHanMuc.type,
            payload: allHanMucDieuKien,
          });
          yield put({
            type: contractActions.setListContractDetail.type,
            payload: updatedDetailData,
          });
        }
      } catch (e) {
        yield put({
          type: contractActions.getListChildrenByIdsFailed.type,
        });
        handleError(e);
      }
      break;
    case "remove-value":
      const newItemRemove = [payload.dataAddRemove.value]
      const filteredArrayRemove = filterChildrenByIds(payload?.data, newItemRemove);
      yield put({
        type: contractActions.setListContractDetail.type,
        payload: filteredArrayRemove,
      });
      break;
    case "clear":
      const listItemClear = payload.dataAddRemove.map((e) => e.value)
      const filteredArrayClear = filterChildrenByIds(payload?.data, listItemClear);
      yield put({
        type: contractActions.setListContractDetail.type,
        payload: filteredArrayClear,
      });
      break;
    default:
      break;
  }
}

function addToParentChildren(data, itemsToAdd) {
  const updatedData = data.map((item) => {
    const updatedItem = { ...item };
    if (item.children) {
      updatedItem.children = addToParentChildren(item.children, itemsToAdd);
    }
    return updatedItem;
  });
  // Merge updatedData with itemsToAdd that match idQuyenLoiBaoHiem and idParentQuyenLoiBaoHiem
  for (const itemToAdd of itemsToAdd) {
    if (itemToAdd.idParentQuyenLoiBaoHiem === null) {
      updatedData.push(itemToAdd);
    } else {
      const parentItem = updatedData.find(item => item.idQuyenLoiBaoHiem === itemToAdd.idParentQuyenLoiBaoHiem);
      if (parentItem) {
        if (!parentItem.children) {
          parentItem.children = [];
        }
        // Check if itemToAdd with the same idQuyenLoiBaoHiem already exists in children
        const existingItem = parentItem.children.find(child => child.idQuyenLoiBaoHiem === itemToAdd.idQuyenLoiBaoHiem);
        if (!existingItem) {
          parentItem.children.push(itemToAdd);
        }
      }
    }
  }

  return updatedData;
}

function addDsHanMucDieuKienToChildren(children) {
  if (!children || children.length === 0) {
    return children;
  }
  // Duyệt qua từng phần tử trong mảng children
  return children.map((child) => {
    // Tạo một bản sao của child với dsHanMucDieuKien được thêm vào
    const updatedChild = {
      ...child,
      dsHanMucDieuKien: child.idParentQuyenLoiBaoHiem === null
        ? null
        : (child.dsHanMucDieuKien?.length > 0
          ? child.dsHanMucDieuKien.map((element,index) => ({
              ...element,
              idRow: `${child.idQuyenLoiBaoHiem}-${index}`,
              idParent : `${child?.idQuyenLoiBaoHiem}-${element?.capDo}`
            }))
          : null
        ),
    };
    // Đệ quy để xử lý các phần tử con của child
    if (child.children && child.children.length > 0) {
      updatedChild.children = addDsHanMucDieuKienToChildren(child.children);
    }
    return updatedChild;
  });
}

function filterChildrenByIds(dataDetail, listIdFilter) {
  return dataDetail.map((item) => {
    const updatedItem = { ...item };
    // Lọc dsLinkedDKBH nếu có chứa phần tử nào trong listIdFilter
    if (updatedItem.dsLinkedDKBH && updatedItem.dsLinkedDKBH.length > 0) {
      updatedItem.dsLinkedDKBH = updatedItem.dsLinkedDKBH.filter(
        (linkedItem) => !listIdFilter.includes(linkedItem)
      );
    }
    if (item.children && item.children.length > 0) {
      // Sử dụng filter để chỉ giữ lại các phần tử có idQuyenLoiBaoHiem thuộc mảng arr2
      updatedItem.children = item.children.filter((child) =>
        !listIdFilter.includes(child.idQuyenLoiBaoHiem)
      );
      // Thực hiện đệ quy cho mảng children
      updatedItem.children = filterChildrenByIds(updatedItem.children, listIdFilter);
    }
    return updatedItem;
  });
}

function getAllHanMucDieuKien(data) {
  const result = [];
  function traverse(node) {
    if (node.dsHanMucDieuKien) {
      result.push(...node.dsHanMucDieuKien);
    }
    if (node.children) {
      for (const child of node.children) {
        traverse(child);
      }
    }
  }
  for (const item of data) {
    traverse(item);
  }
  return result;
}

export default function* contractSaga() {
  yield takeEvery(contractActions.getListContractDetail.type, handleGetContractDetail);
  yield takeEvery(contractActions.getListContractDetailHSKCB.type, handleGetContractDetailHSKCB);
  yield takeEvery(contractActions.getListContractDetailHSTTTT.type, handleGetContractDetailHSTTT);
  yield takeEvery(contractActions.getListContractParents.type, handleGetListContractParents);
  yield takeEvery(contractActions.getListChildrenByIds.type, handleGetListChildrenByIds);
  yield takeEvery(contractActions.getListLevelContract.type, handleGetListLevelContract);
  yield takeEvery(contractActions.getListUnitCal.type, handleGetListUnitCal);
  yield takeEvery(contractActions.getListUnitTime.type, handleGetListUnitTime);
}
