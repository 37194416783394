import { call, put, takeEvery } from "redux-saga/effects";
import { permissionsActions } from "./permissionsSlice";
import { getListPermissionMenu, getListPermissionPage } from "./service";
import { handleError } from "_metronic/helpers";
import { setMenuItem } from "_metronic/helpers/CommonHelpers";


function* handleGetListPermissionsPage({ payload }) {
  try {
    const resultsListPermissionsPage = yield call(getListPermissionPage, payload?.listKey);
    if (resultsListPermissionsPage?.result) {
      const result = resultsListPermissionsPage?.result
      const permissionTrueKeys = Object.keys(result).filter(key => result[key] === true);
      yield put({
        type: permissionsActions.getListPermissionsPageSuccess.type,
        payload: {
          listPermissionsPage: permissionTrueKeys,
          path : payload?.path
        } ,
      });
    }
  } catch (e) {
    yield put({
      type: permissionsActions.getListPermissionsPageFailed.type,
      payload: {
        path : payload?.path
      } ,
    });
    handleError(e)
  }
}

function* handleGetListPermissionsMenu({ payload }) {
  try {
    const resultsListPermissionsMenu = yield call(getListPermissionMenu, payload);
    if (resultsListPermissionsMenu?.result) {
      const result = resultsListPermissionsMenu?.result
      const permissionTrueKeys = Object.keys(result).filter(key => result[key] === true);
      yield put({
        type: permissionsActions.getListPermissionsMenuSuccess.type,
        payload: permissionTrueKeys,
      });
      if(payload?.firstLogin && permissionTrueKeys){
        setMenuInner(permissionTrueKeys)
      }  
    }
  } catch (e) {
    yield put({
      type: permissionsActions.getListPermissionsMenuFailed.type,
    });
    handleError(e)
  }
}

function setMenuInner(permissionTrueKeys) {
  switch (true) {
    case containsPermission("thamdinhchudong.", permissionTrueKeys):
     return setMenuItem("thamdinhchudong")
    case containsPermission("thamdinhtudong.", permissionTrueKeys):
     return setMenuItem("thamdinhtudong") 
    case containsPermission("thanhquyettoan.", permissionTrueKeys):
     return setMenuItem("thanhquyettoan")  
    case containsPermission("danhmuc.", permissionTrueKeys):
      return setMenuItem("danhmuc")
    case containsPermission("quantrihethong.", permissionTrueKeys):
      return setMenuItem("quantrihethong")
    case containsPermission("tienichtrogiup.", permissionTrueKeys):
      return setMenuItem("tienichtrogiup")
    case containsPermission("hopdong.", permissionTrueKeys):
      return setMenuItem("hopdong")
    case containsPermission("quyettoan", permissionTrueKeys):
      return setMenuItem("quyettoan")
    case containsPermission("thongkebaocao.", permissionTrueKeys):
      return setMenuItem("thongkebaocao") 
    default:
      break;
  }
}

function containsPermission(key:any, listPermissionMenu:any) {
  return listPermissionMenu.some((permission) => permission.includes(key));
}

export default function* permissionsSaga() {
  yield takeEvery(permissionsActions.getListPermissionsPage.type, handleGetListPermissionsPage);
  yield takeEvery(permissionsActions.getListPermissionsMenu.type, handleGetListPermissionsMenu);

}
