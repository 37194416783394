import { removeNullFields } from '_metronic/helpers'
import {api} from 'app/config/api'
import axios from 'axios'

export const getListTraCuuNDBH = (form) => {
  const formTemp = removeNullFields(form)
  const objString = '?' + new URLSearchParams(formTemp).toString()
  return new Promise((resolve, reject) => {
    axios
    .get(`${api.API_HOSO_TTTT_FIND_CONTRACT_BY_MULTI_DATA}${objString}`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

