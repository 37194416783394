import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  objectSearchPhieu: {
    page: 0,
    items_per_page: 10,
    soPhieuThanhToan: null,
    maDoiSoatBenhVien: null,
    soHoSoBoiThuong: null,
    maHdbhnt: null,
    hoTen: null,
    donViId: null,
    maCskcb: null,
    vip: null,
    tongTienPhieu: null,
    ngayDeNghi: null,
    trangThaiPhieu: [
      {label: 'Chờ duyệt', value: 1},
      {label: 'Cán bộ quản lý ngân sách đã duyệt', value: 5},
    ],
    ngayThanhToan: null,
    trangThaiThanhToan: null,
    maGiaoDich: null,
    loiGiaoDich: null,
    loaiHoSo: null,
    maLoaiKCB: null,
    ngayDuyet: null,
    sortField: null,
    sortOrder: null,
  },
  objectSearchPhieuFilter: {
    page: 0,
    items_per_page: 10,
    soPhieuThanhToan: null,
    maDoiSoatBenhVien: null,
    soHoSoBoiThuong: null,
    maHdbhnt: '',
    hoTen: '',
    donViId: null,
    maCskcb: null,
    vip: null,
    tongTienPhieu: null,
    ngayDeNghi: null,
    trangThaiPhieu: null,
    ngayThanhToan: null,
    trangThaiThanhToan: null,
    maGiaoDich: null,
    loiGiaoDich: null,
    loaiHoSo: null,
    maLoaiKCB: null,
    ngayDuyet: null,
    sortField: null,
    sortOrder: null,
  },
  objectSearchPhieuDefault: {
    page: 0,
    items_per_page: 10,
    soPhieuThanhToan: null,
    maDoiSoatBenhVien: null,
    soHoSoBoiThuong: null,
    maHdbhnt: null,
    hoTen: null,
    donViId: null,
    maCskcb: null,
    vip: null,
    tongTienPhieu: null,
    ngayDeNghi: null,
    trangThaiPhieu: null,
    ngayThanhToan: null,
    trangThaiThanhToan: null,
    maGiaoDich: null,
    loiGiaoDich: null,
    loaiHoSo: null,
    maLoaiKCB: null,
    ngayDuyet: null,
    sortField: null,
    sortOrder: null,
  },
  loadingGetListPhieu: false,
  totalGetListPhieu: null,
  listPhieu: [],
  reload: false,
}

export const phieuDeNghiSlice = createSlice({
  name: 'phieuDeNghi',
  initialState,
  reducers: {
    getListPhieu(state, action) {
      state.loadingGetListPhieu = true
    },
    getListPhieuSuccess(state, action) {
      state.listPhieu = action.payload.results
      state.loadingGetListPhieu = false
      state.totalGetListPhieu = action.payload.total
    },
    getListPhieuFailed(state) {
      state.loadingGetListPhieu = false
      state.listPhieu = []
    },
    setObjectPhieu(state, action) {
      state.objectSearchPhieu = {...state.objectSearchPhieu, ...action.payload}
      state.objectSearchPhieuFilter = {...state.objectSearchPhieuFilter, ...action.payload}
    },
    setObjectPhieuFilter(state, action) {
      state.objectSearchPhieuFilter = {
        ...state.objectSearchPhieuFilter,
        ...action.payload,
      }
    },
    setObjectPhieuDefault(state) {
      state.objectSearchPhieuFilter = initialState.objectSearchPhieuFilter
    },
    setObjectPhieuNoMerge(state, action) {
      console.log(action.payload)
      state.objectSearchPhieu = {...action.payload}
      // state.objectSearchPhieuFilter = {...action.payload}
    },
  },
})
export const phieuDeNghiActions = phieuDeNghiSlice.actions
const phieuDeNghiReducer = phieuDeNghiSlice.reducer

export default phieuDeNghiReducer
export const selectObjectSearchPhieu = (state) => state.phieuDeNghi.objectSearchPhieu
export const selectObjectSearchPhieuDefault = (state) => state.phieuDeNghi.objectSearchPhieuDefault
export const selectObjectSearchPhieuFilter = (state) => state.phieuDeNghi.objectSearchPhieuFilter
export const selectTotalDanhSachPhieu = (state) => state.phieuDeNghi.totalGetListPhieu
export const selectDanhSachPhieu = (state) => state.phieuDeNghi.listPhieu
export const selectLoadingDanhSachPhieu = (state) => state.phieuDeNghi.loadingGetListPhieu
