import { api } from "app/config/api";
import axios from "axios";

export const getListWarningManualAssessment = (payload) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${payload?.type === 1 ? api.API_HOSO_KCB : api.API_HOSO_TTTT}/${payload.id}/canh-bao`)
            .then((value) => {
                if(payload?.type === 1){
                    resolve(value?.data);
                }else {
                    resolve(value?.data?.result);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
};