export function getStartOfDay(dt){
    var date = new Date();
    if(dt!=null){
      //FIXME: validate dt
      date = new Date(dt);
    }
    var y = date.getFullYear(), m = date.getMonth(), d = date.getDate();
    return new Date(y, m, d, 0, 0, 0);
};
export function getEndOfDay(dt){
    var date = new Date();
    if(dt!=null){
      //FIXME: validate dt
      date = new Date(dt);
    }
    var y = date.getFullYear(), m = date.getMonth(), d = date.getDate();
    return new Date(y, m, d, 23, 59, 59);
};