import {api} from 'app/config/api'
import axios from 'axios'

export const getListCSKCB = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGOTY_CSKCB}/display`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListInsuranceCompany = () => {
  return new Promise((resolve, reject) => {
    axios

      .get(`${api.API_INSURANCE_COMPANY}/display`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}


export const getListProvince = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_CATEGORY_PROVINCE + `/display`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const getListDistrict = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_CATEGORY_DISTRICT + `/display?idTinhThanh=${data?.idTinhThanh}`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const getListWard = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        api.API_CATEGORY_WARD +
          `/display?idTinhThanh=${data?.idTinhThanh}&idQuanHuyen=${data?.idQuanHuyen}`
      )
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListLyDoTuChoi = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGOTY_LY_DO_TU_CHOI}/display`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListLoaiFile = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGORY_TYPE_FILE}/display`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListCauHinhLoaiFileHD = (data) => {
  console.log(data , 'cate cau hinh');
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGORY_TYPE_FILE_CONTRACT}/display?idCongTyBaoHiem=${data?.value}`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

