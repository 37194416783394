import { api } from "app/config/api";
import axios from "axios";

export const getListPermissionPage = (data) => {
    const queryString = data.map(key => `key=${key}`).join('&');
    return new Promise((resolve, reject) => {
        axios
            .get(`${api.API_CHECK_PERMISSION}?${queryString}`)
            .then(( value ) => {
                resolve(value?.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getListPermissionMenu = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${api.API_CHECK_PERMISSION_MENU}`)
            .then(( value ) => {
                resolve(value?.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};


