import {api} from 'app/config/api'
import axios from 'axios'

export const getDanhSachKenhKhaiThacQuaDaiLy = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_INSURANCE_COMPANY_AGENCY)
      .then(({data}) => {
        let listUnshiftAll = data?.result
        // const listUnshiftAll = data?.result?.map((item) => {
        //   return {
        //     label: item.label,
        //     value: item.value,
        //     customAbbreviation: item.extraData ? item.extraData.tenNhanVien : null,
        //   }
        // })
        if (payload?.showAll) {
          listUnshiftAll.unshift({
            label: 'Tất cả',
            value: null,
          })
          resolve(listUnshiftAll)
        } else {
          resolve(listUnshiftAll)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getDanhSachKenhKhaiThacTrucTiep = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_LIST_KENHKHAITHACTRUCTIEP + payload?.idDonVi)
      .then(({data}) => {
        if (payload?.showAll) {
          const listUnshiftAll = [...data.result]
          listUnshiftAll.unshift({
            label: 'Tất cả',
            value: null,
          })
          resolve(listUnshiftAll)
        } else {
          resolve(data.result)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getDanhSachKenhKhaiThacQuaMoiGioi = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_INSURANCE_COMPANY_MEDIUM)
      .then(({data}) => {
        const listUnshiftAll = data?.map((item) => ({value: item.id, label: item.tenDonVi}))
        if (payload?.showAll) {
          listUnshiftAll.unshift({
            label: 'Tất cả',
            value: null,
          })
          resolve(listUnshiftAll)
        } else {
          resolve(listUnshiftAll)
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const getListSanPhamBaoHiem = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_PRODUCT_INSURANCE_TYPE)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const getListKhachHang = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_CUSTOMER_TYPE)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const getListHopDongBaoHiem = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_INSURANCE_TYPE)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const getDanhSachPhuongThucBoiThuongBenThuBa = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_INSURANCE_COMPANY_TPA)
      .then((results) => {
        const newArray = results?.data?.map((item) => ({value: item.id, label: item.tenDonVi}))
        resolve(newArray)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
