import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  loading: true,
  listContractDetail: null,
  listContractParents: [],
  checkedIdParents: [],
  listChildrenByIds: [],
  listLevelContract: null,
  loadingLevelContract: true,
  listUnitCal: [],
  listUnitTime : [],
  listAllDanhSachHanMuc: [],
  firstItemUnitCal: {},
  defaultUnit: {
    capDo: null,
    idDonViTinh: null,
    tenDonViTinh: null,
    hanMuc: null,
    id: null,
  },
  typeContract : null,
  stageHopDong : [],
  nodes: [],
  checkEditContractPermission : true
}

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setCheckEditContractPermission(state,action){
      state.checkEditContractPermission = action.payload
    },
    setNodes(state,action){
      state.nodes = action.payload
    },
    unmountContract(state){
      state.loading = true
      state.listContractDetail = null
    },
    setTypeContract(state,action) {
      state.typeContract = action.payload
    },
    getListContractDetailHSKCB(state, action) {
      state.loading = true
    },
    getListContractDetailHSKCBSuccess(state, action) {
      state.listContractDetail = action.payload
      state.loading = false
    },
    getListContractDetailHSKCBFailed(state) {
      state.loading = false
      state.listContractDetail = null
    },

    getListContractDetailHSTTTT(state, action) {
      state.loading = true
    },
    getListContractDetailHSTTTTSuccess(state, action) {
      state.listContractDetail = action.payload
      state.loading = false
    },
    getListContractDetailHSTTTTFailed(state) {
      state.loading = false
      state.listContractDetail = []
    },
    //detail
    getListContractDetail(state, action) {
      state.loading = true
    },
    getListContractDetailSuccess(state, action) {
      state.listContractDetail = action.payload
      state.loading = false
    },
    getListContractDetailFailed(state) {
      state.loading = false
      state.listContractDetail = []
    },
    setListContractDetail(state, action) {
      state.listContractDetail = action.payload
    },
    //parent
    setCheckedIdParents(state, action) {
      state.checkedIdParents = action.payload
    },
    getListContractParents(state, action) {
      // state.loading = true
    },
    getListContractParentsSuccess(state, action) {
      state.listContractParents = action.payload
      // state.loading = false
    },
    getListContractParentsFailed(state) {
      // state.loading = false
      state.listContractParents = []
    },
    setListContractParents(state, action) {
      state.listContractParents = action.payload
    },
    //childrenByIds
    getListChildrenByIds(state, action) {
      // state.loading = true
    },
    getListChildrenByIdsSuccess(state, action) {
      state.listChildrenByIds = action.payload
      // state.loading = false
    },
    getListChildrenByIdsFailed(state) {
      // state.loading = false
      // state.listChildrenByIds = []
    },
    getListLevelContract(state, action) {
      state.listLevelContract = null
      state.loading = true
      state.loadingLevelContract = true;
    },
    getListLevelContractSuccess(state, action) {
      state.listLevelContract = action.payload
      state.loadingLevelContract = false;
    },
    getListLevelContractFailed(state) {
      state.listLevelContract = null
      state.loadingLevelContract = false;
    },
    setListLevelContract(state, action) {
      state.listLevelContract = action.payload
    },
    getListUnitCal(state, action) {
      state.listUnitCal = action.payload
    },
    getListUnitCalSuccess(state, action) {
      state.listUnitCal = action.payload
      state.defaultUnit =
        state.listUnitCal != null ? state.listUnitCal.at(0) : initialState.defaultUnit
    },
    getListUnitCalFailed(state) {
      state.listUnitCal = []
    },
    setListUnitCal(state, action) {
      state.listUnitCal = action.payload
    },
    setFirstItemUnitCal(state, action) {
      state.firstItemUnitCal = action.payload
      state.defaultUnit = action.payload
    },
    getListUnitTime(state, action) {
       state.listUnitTime = action.payload
    },
    getListUnitTimeSuccess(state, action) {
      state.listUnitTime = action.payload
    },
    getListUnitTimeFailed(state) {
      state.listUnitTime = []
    },
    // updateHanMuc(state, action) {},
    setListAllDanhSachHanMuc(state, action) {
      state.listAllDanhSachHanMuc = action.payload
    },
    setStageHopDong(state, action){
      state.stageHopDong = action.payload
    }
  },
})
export const contractActions = contractSlice.actions
const contractReducer = contractSlice.reducer

export default contractReducer

export const selectLoadingContract = (state) => state.contract.loading
export const selectLoadingLevelContract = (state) => state.contract.loadingLevelContract
export const selectListContractDetail = (state) => state.contract.listContractDetail
export const selectListContractParents = (state) => state.contract.listContractParents
export const selectCheckedIdParents = (state) => state.contract.checkedIdParents
export const selectListChildrenByIds = (state) => state.contract.listChildrenByIds
export const selectListLevelContract = (state) => state.contract.listLevelContract
export const selectListUnitCal = (state) => state.contract.listUnitCal
export const selectListUnitTime = (state) => state.contract.listUnitTime
export const selectListAllDanhSachHanMuc = (state) => state.contract.listAllDanhSachHanMuc
export const selectFirstItemUnitCal = (state) => state.contract.firstItemUnitCal
export const selectDefaultUnit = (state) => state.contract.defaultUnit
export const selectTypeContract = (state) => state.contract.typeContract
export const selectStageHopDong = (state) => state.contract.stageHopDong
export const selectNodes = (state) => state.contract.nodes
export const selectCheckEditContractPermission = (state) => state.contract.checkEditContractPermission


