
import * as Forge from 'node-forge';



export function forgeEncryptRSA(msg, encodedPublic) {
  // load public key from PEM-formatted string
  const pem = `-----BEGIN PUBLIC KEY-----${encodedPublic}-----END PUBLIC KEY-----`;
  const publicKey = Forge.pki.publicKeyFromPem(pem);

  // convert string to UTF-8 encoded bytes
  // const buffer = Forge.util.createBuffer(msg, 'utf8');
  const buffer = Forge.util.createBuffer(msg);
  const bytes = buffer.getBytes();

  // encrypt data with a public key using RSAES PKCS#1 v1.5
  const encrypted = publicKey.encrypt(bytes, 'RSAES-PKCS1-V1_5');
  console.log(Forge.util.encode64(encrypted));
  // base64-encode encrypted data to send to server
  return Forge.util.encode64(encrypted);
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // tslint:disable-next-line:no-bitwise one-variable-per-declaration
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}