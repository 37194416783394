import { FC } from 'react';
import { Link } from 'react-router-dom';

const Error401: FC = () => {
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
              <div className='text-center mb-5'>
                <h1
                  className='fw-bolder text-danger mb-4'
                  style={{
                    fontSize: '200px',
                    lineHeight: '1.2',
                    margin: '0',
                  }}
                >
                  401
                </h1>
                <h2 className='fw-semibold fs-4 text-gray-500'>
                  Unauthorized Access
                </h2>
              </div>
              <div className='mb-3'>
                <p className='text-muted fs-6'>
                  You do not have permission to access this resource.
                </p>
              </div>
              <div className='text-center mb-0'>
                <Link to='/apps/account/overview' className='btn btn-lg btn-primary'>
                  Return Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Error401 };
