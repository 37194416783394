import {toAbsoluteUrl} from '_metronic/helpers'
import LoadingIndicator from 'app/components/loading'
import {Modal} from 'react-bootstrap'

function PopupWarning(props: {
  show: boolean
  onAgree?: any
  title?: string
  onHide: any
  subTitle?: string
  textAgree?: string
  isVuotHanMuc?: boolean
  isVuotTime?: boolean
  dataValidationTime?: any
  textDecline?: string
  isLoading?: boolean
  loadingBtnSuccess?: boolean
  type?:string
}) {
  return (
    <Modal centered show={props?.show} onHide={() => {}}>
      <Modal.Body>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 10,
          }}
          className='row'
        >
          <div
            style={{
              width: 70,
              height: 70,
              borderRadius: 40,
              backgroundColor: '#FCEEED',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginBottom: 20,
            }}
          >
            <img src={toAbsoluteUrl('/media/common/icon_warning.svg')} />
          </div>
          <span style={{fontSize: 20, fontWeight: 'bold', textAlign: 'center', paddingBottom: 5}}>
            {props.title || ''}
          </span>
          <span
            style={{
              fontSize: 14,
              textAlign: 'center',
              paddingBottom: props?.isVuotTime ? 10 : 20,
              width: '80%',
            }}
          >
            {props.subTitle || ''}
          </span>
          <span style={{fontSize: 14}}>
            {props?.isVuotTime && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  textAlign: 'left',
                  marginBottom: '10px',
                  paddingLeft: '9em',
                  paddingBottom: '20px',
                }}
              >
                {/* <div style={{fontWeight: 900}}>Các loại ngày quá thời hạn</div> */}
                {/* <div> */}
                {props?.dataValidationTime?.ngayKham && (
                  <span>
                    <li>{props?.dataValidationTime?.ngayKham}</li>
                  </span>
                )}
                {/* </div> */}
                {/* <div> */}
                {props?.dataValidationTime?.ngayVaoVien && (
                  <span>
                    <li>{props?.dataValidationTime?.ngayVaoVien}</li>
                  </span>
                )}
                {props?.dataValidationTime?.ngayRaVien && (
                  <span>
                    <li>{props?.dataValidationTime?.ngayRaVien}</li>
                  </span>
                )}
                {props?.dataValidationTime?.dsQuyenLoiHoSo && (
                  <span>
                    <li>{props?.dataValidationTime?.dsQuyenLoiHoSo?.message}</li>
                  </span>
                )}
                {props?.dataValidationTime?.dsThongTinHoaDon && (
                  <span>
                    <li>{props?.dataValidationTime?.dsThongTinHoaDon}</li>
                  </span>
                )}
                {props?.dataValidationTime?.duplicateHoSoTTTT && (
                  <span>
                    <li>{props?.dataValidationTime?.duplicateHoSoTTTT}</li>
                  </span>
                )}
                {/* </div> */}
                {/* <div> */}

                {/* // </div> */}
              </div>
            )}
          </span>
          <div className='d-flex justify-content-center'>
            <button
              className='btn btn-secondary'
              style={{marginRight: 10, width: 179, borderRadius: '10px'}}
              onClick={() => {
                props.onHide()
              }}
            >
              {props?.textDecline ? props?.textDecline : 'Đóng'}
            </button>
            {props?.isLoading === true ? (
              !props?.loadingBtnSuccess  ? (
                <button
                  style={{
                    width: 179,
                    backgroundColor: '#FC6B03',
                    borderRadius: '10px',
                    color: 'white',
                  }}
                  className='btn '
                  onClick={() => {
                    props.onAgree()
                    props.onHide()
                  }}
                >
                  {props?.textAgree}
                </button>
              ) : (
                <button
                  style={{
                    width: 179,
                    backgroundColor: '#FC6B03',
                    borderRadius: '10px',
                    color: 'white',
                  }}
                  className='btn '
                >
                  <LoadingIndicator />
                </button>
              )
            ) : (
              !props.isVuotHanMuc && (props?.type !== "ho_so")? (
                <button
                  style={{
                    width: 179,
                    backgroundColor: '#FC6B03',
                    borderRadius: '10px',
                    color: 'white',
                  }}
                  className='btn '
                  onClick={() => {
                    props.onAgree()
                    props.onHide()
                  }}
                >
                  {props?.textAgree}
                </button>
              ) : null
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PopupWarning
