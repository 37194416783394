import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import {api} from '../../../config/api'
import {AES, enc, pad, mode} from 'crypto-js'
import {forgeEncryptRSA} from 'app/utils/crypto'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
// export function login(username: string, passwordHash: string) {
//   let password = encrypt(passwordHash);
export function login(username: string, passwordHash: string, publicKey: string, cookiesValue : string) {
  const encryptedData = forgeEncryptRSA(
    JSON.stringify({
      username: username,
      password: passwordHash,
    }),
    publicKey
  )
  return axios.post<AuthModel>(api.API_LOGIN_V3, {
    data: encryptedData,
    sessionId : cookiesValue
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  // return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
  //   email,
  // })
}

export function encrypt(plainText: string) {
  // var iv = lib.WordArray.random(16);
  // var iv_hex = enc.Hex.stringify(iv);
  // var iv = enc.Utf8.parse("r0yy7e67p49ee4d7");
  // const key = process.env.REACT_APP_AES_KEY

  // var et = AES.encrypt(plainText, key, {
  //   iv: iv,
  //   padding: pad.Pkcs7,
  //   mode: mode.CBC,
  // });
  // return (
  //   et.ciphertext.toString(enc.Base64) +
  //   "@" +
  //   iv.toString(enc.Base64)
  // );
  var key = enc.Utf8.parse('8K4sT58BhZA11ixmhI4g6IfmKFXeHd8W')
  var iv = enc.Utf8.parse('r0yy7e67p49ee4d7')
  var et = AES.encrypt(plainText, key, {iv: iv, padding: pad.Pkcs7, mode: mode.CBC})

  return et.ciphertext.toString(enc.Base64) + '@' + iv.toString(enc.Base64)
}

export function getUserByToken() {
  return axios.get<UserModel>(api.API_USER_INFOR)
}

export function refreshToken(token: any) {
  // return axios.post<any>(api.API_REFRESH_TOKEN, {
  //   token,
  // })
}
