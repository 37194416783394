import { permissionsActions } from "app/redux/permissions/permissionsSlice";
import store from "../../app/redux/store";
import { commonActions } from './../../app/redux/common/commonSlice';


export const setPageTitle = (pathname: string) => store.dispatch(commonActions.setPageTitle(pathname))
export const setMenuItem = (value : any) =>store.dispatch(commonActions.setMenuItem(value))
export const getListPermissionsMenu = (value :any) =>store.dispatch(permissionsActions.getListPermissionsMenu(value))
export const getListPermissionsMenuFailed = () =>store.dispatch(permissionsActions.getListPermissionsMenuFailed())



