import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pathPermissionsPage : null,
  listPermissionsPage : [],
  listPermissionsMenu : [],
  loading: true,
  loadingMenu:false
}

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    getListPermissionsPage(state, action) {
      state.loading = true;
    },
    getListPermissionsPageSuccess(state, action) {
      state.listPermissionsPage = action.payload?.listPermissionsPage;
      state.pathPermissionsPage = action.payload?.path
      state.loading = false;
    },
    getListPermissionsPageFailed(state,action) {
      state.loading = false;
      state.listPermissionsPage = []
      state.pathPermissionsPage = action.payload?.path
    },
    getListPermissionsMenu(state, action) {
      state.loadingMenu = true;
    },
    getListPermissionsMenuSuccess(state, action) {
      state.listPermissionsMenu = action.payload;
      state.loadingMenu = false;
    },
    getListPermissionsMenuFailed(state) {
      state.loadingMenu = false;
      state.listPermissionsMenu = []
    }
  },
  
})
export const permissionsActions = permissionsSlice.actions
const permissionsReducer = permissionsSlice.reducer

export default permissionsReducer

export const selectListPermissionsPage = (state: any) => state.permissions.listPermissionsPage
export const selectListPermissionsMenu = (state: any) => state.permissions.listPermissionsMenu
export const selectLoadingPermissionsMenu = (state: any) => state.permissions.loadingMenu
export const selectLoadingPermissionsPage = (state: any) => state.permissions.loading



