/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import axios from 'axios'
import { useIntl } from 'react-intl'
import { api } from 'app/config/api'
import { v4 as uuid } from 'uuid'
import { handleError } from '_metronic/helpers'
import { getListPermissionsMenu } from '_metronic/helpers/CommonHelpers'

export function Login() {
  const [loading, setLoading] = useState(false)
  const [isTimeout, setIsTimeout] = useState(false)
  const [showPasswordText, setShowPasswordText] = useState(true);
  const { saveAuth, setCurrentUser } = useAuth()
  const intl = useIntl()
  // const {roles} = currentUser?.realm
  const loginSchema = Yup.object().shape({
    username: Yup.string()
      .max(50, intl.formatMessage({ id: 'COMMON.MAXIMUM_50_SYMBOLS' }))
      .required(intl.formatMessage({ id: 'AUTH.LOGIN.USERNAME_CANNOT_BE_BLANK' })),
    password: Yup.string()
      .max(50, intl.formatMessage({ id: 'COMMON.MAXIMUM_50_SYMBOLS' }))
      .required(intl.formatMessage({ id: 'COMMON.PASS_CAN_NOT_BE_BLANK' })),
  })

  const initialValues = {
    username: '',
    password: '',
  }

  useEffect(() => {
    if (localStorage.getItem('sessionTimeout') === 'true' && !isTimeout) {
      //ToastMessage('error', intl.formatMessage({ id: 'AUTH.LOGIN.LOGIN_SESSION_HAS_EXPIRED' }))
      localStorage.setItem('sessionTimeout', "false")
    }
  }, [localStorage.getItem('sessionTimeout')])


  async function getPermissionLoginToken() {
    try {
      const sessionId = uuid();
      const response = await axios.post(api.API_PERMISSION + '/' + sessionId);
      // let expires = new Date();
      // expires.setTime(expires.getTime() + Number(COOKIES_TIMEOUT));
      // setCookie('sessionId', sessionId, { path: '/', expires });
      const obj ={
        sessionId:sessionId,
        responseId : response?.data?.id
      }
      return obj
    } catch (err) {
      handleError(err);
      return null; // Trả về null hoặc một giá trị mặc định tùy theo trường hợp.
    }
  }

  // const loginFlow = (tokenLogin:string)=>{
  //   const {data} = login()
  // }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      // getPermissionLoginToken()
      const publicKey = await getPermissionLoginToken();
      if (publicKey !== null) {
        try {
          const { data } = await login(values.username, values.password, publicKey.responseId, publicKey.sessionId);
          if (data) {
            saveAuth(data);
            const dataUser = await getUserByToken();
            if (dataUser?.data) {
              await setCurrentUser(dataUser.data);
            }
            if (data?.access_token) {
              //redux get list permisions menu
              await getListPermissionsMenu({ firstLogin: true });
            }
          }
        } catch (error: any) {
          saveAuth(undefined);
          setSubmitting(false)
          setLoading(false)
          const { status } = error?.response
          switch (status) {
            case 403:
              setStatus("Đăng nhập không thành công, tài khoản đã bị khóa.");
              break;
            case 400:
            case 401:
              setIsTimeout(true)
              setStatus(intl.formatMessage({ id: 'AUTH.LOGIN.ACCOUNT_OR_PASS_IS_INCORRECT' }));
              break;
            case 406:
              setStatus("Đăng nhập không thành công, vui lòng tải lại trình duyệt");
              break;
            default:
              handleError(error)
          }
        }
      } else {
        // Xử lý tùy chọn nếu không có tokenLogin
        setStatus("Không thể lấy token đăng nhập.");
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
      style={{paddingTop:15}}
    >
      {formik.status ? (
        <div className='mt-15 mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({ id: 'COMMON.USER_NAME' })}
        </label>
        <input
          placeholder={intl.formatMessage({ id: 'COMMON.USER_NAME' })}
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control bg-transparent hide-eye-icon',
            { 'is-invalid': formik.touched.username && formik.errors.username },
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          type='text'
          name='username'
          autoComplete='off'
          onKeyPress={
            (e) => {
              if (e.key === ' ' || e.key === ' ') {
                // Ngăn việc nhập dấu "-"
                e.preventDefault();
              }
            }
          }
          onBlur={(e) => {
            const trimmedValue = e.target.value.trim();
            formik.setFieldValue('username', trimmedValue);
            formik.handleBlur(e);
          }}
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.username}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({ id: 'COMMON.PASS' })}
        </label>
        <div className="input-group mb-3">
          <input type={!showPasswordText ? "text" : 'password'}
            placeholder={intl.formatMessage({ id: 'AUTH.LOGIN.ENTER_PASS' })}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            onBlur={(e) => {
              const trimmedValue = e.target.value.trim();
              formik.setFieldValue('password', trimmedValue);
              formik.handleBlur(e);
            }}
            onKeyPress={
              (e) => {
                if (e.key === ' ' || e.key === ' ') {
                  e.preventDefault();
                }
              }
            }
            className={clsx(
              'hide-eye-icon form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )} />
          <span className="input-group-text" id="basic-addon1">
            <div onClick={() => setShowPasswordText((prevState: any) => !prevState)} >
              {showPasswordText ? <img style={{ width: '90%' }} src={'/media/common/icon-eye-on.svg'} /> : <img style={{ width: '90%' }} src={'/media/common/icon-eye-off.svg'} />}
            </div>
          </span>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
        {/* <Link to="/auth/forgot-password" className="link-primary">
          Forgot Password ?
        </Link> */}
      </div>

      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>{intl.formatMessage({ id: 'AUTH.LOGIN.LOG_IN' })}</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'COMMON.PLS_WAIT' })}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
