import { api } from "app/config/api";
import axios from "axios";

export const getContractDetail = (id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${api.API_CONTRACT}/${id}/dieu-khoan-bao-hiem`)
            .then((value) => {
                resolve(value?.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getContractDetailHSKCB = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .get( `${api.API_CONTRACT_PEOPLE}?maHopDong=${data?.maHDBHNT}&soCCCD=${data?.soCCCD}&hoTen=${data?.hoTen}&ngaySinh=${data?.ngaySinh}&gioiTinh=${data?.gioiTinh}`)
            .then((value) => {
                resolve(value?.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getContractDetailHSTTTT = (data) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${api.API_CONTRACT_PEOPLE}?maHopDong=${data?.maTheBHSK}&soCCCD=${data?.soCCCDCMT}&hoTen=${data?.hoTen}&ngaySinh=${data?.ngaySinh}&gioiTinh=${data?.gioiTinh}`)
            .then((value) => {
                resolve(value?.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getListChildrenByIds = (listId) => {
    const queryString = listId.map((element) => `ids=${element}`).join('&')
    return new Promise((resolve, reject) => {
        axios
            .get(`${api.API_INSURANCE_TERMS_CHILDREN}${queryString ? `?${queryString}` : ''}`)
            .then((value) => {
                resolve(value?.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getContractParentsStep5 = (payload) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${api.API_INSURANCE_CONTRACT_V2}/${payload?.id}/dieu-khoan-bao-hiem/parents`)
            .then((value) => {
                resolve(value?.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getContractParentsHSKCBHSTTTT = (payload) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${api.API_INSURANCE_TERMS_PARENTS_V2}?maHopDong=${payload?.maHDBHNT}&soCCCD=${payload?.soCCCD}&hoTen=${payload?.hoTen}&ngaySinh=${payload?.ngaySinh}&gioiTinh=${payload?.gioiTinh}`)
            .then((value) => {
                resolve(value?.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getListLevelContract = (id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${api.API_CONTRACT}/${id}/cap-do-han-muc`)
            .then((value) => {
                resolve(value?.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getListUnitCal = (id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${api.API_UNIT_CALCULATOR}`)
            .then((value) => {
                resolve(value?.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getListUnitTime = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${api.API_UNIT_TIME}`)
            .then((value) => {
                resolve(value?.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};



