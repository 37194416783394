import { combineReducers } from "redux";

import commonReducer from "./common/commonSlice";
import searchFilterReducer from "../modules/proactive-appraisal/redux/searchFilter/searchFilterSlice";
import categoryReducer from "../modules/category/redux/category/categorySlice";
import permissionsReducer from "./permissions/permissionsSlice";
import contractReducer from "../modules/contract-customer/redux/contract/contractSlice";
import listContractReducer from "../modules/contract-customer/redux/listContract/listContractSlice";
import contractStepThirdReducer from "../modules/contract-customer/redux/contractStepThird/contractStepThirdSlice";
import contractStepTwoReducer from "../modules/contract-customer/redux/contractStepTwo/contractStepTwoSlice";
import contractStepOneReducer from "../modules/contract-customer/redux/contractStepOne/contractStepOneSlice";
import manualAssessmentReducer from "../modules/proactive-appraisal/redux/manualAssessment/manualAssessmentSlice";
import phieuDeNghiReducer from "app/modules/payment/redux/phieuDeNghi/phieuDeNghiSlice";
import traCuuReducer from "app/modules/tra-cuu/redux/traCuu/traCuuSlice";

const rootReducer = combineReducers({
  common: commonReducer,
  searchFilter : searchFilterReducer,
  category : categoryReducer,
  permissions : permissionsReducer,
  contract : contractReducer,
  listContract: listContractReducer,
  contractStepThird:contractStepThirdReducer,
  contractStepTwo:contractStepTwoReducer,
  contractStepOne:contractStepOneReducer,
  manualAssessment : manualAssessmentReducer,
  phieuDeNghi:phieuDeNghiReducer,
  traCuu: traCuuReducer 
});
export default rootReducer;
