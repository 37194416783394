import { FC } from 'react';
import { Link } from 'react-router-dom';

const Error403: FC = () => {
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid'>
        <div className='d-flex flex-column flex-center text-center p-10'>
          <div className='card card-flush w-lg-650px py-5'>
            <div className='card-body py-15 py-lg-20'>
              {/* begin::Title */}
              <div className='text-center mb-5'>
                <h1
                  className='fw-bolder text-danger mb-4'
                  style={{
                    fontSize: '200px',
                    lineHeight: '1.2',
                    margin: '0',
                  }}
                >
                  403
                </h1>
                <h2 className='fw-semibold fs-4 text-gray-500'>
                  Truy cập bị từ chối
                </h2>
              </div>
              {/* end::Title */}

              {/* begin::Illustration */}
              <div className='mb-3'>
                <div className='text-center'>
                  <p className='text-muted fs-6'>
                    Bạn không có quyền truy cập vào trang này.
                  </p>
                </div>
              </div>
              {/* end::Illustration */}

              {/* begin::Link */}
              <div className='text-center mb-0'>
                <Link to='/apps/account/overview' className='btn btn-lg btn-primary'>
                  Trang chủ
                </Link>
              </div>
              {/* end::Link */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Error403 };
