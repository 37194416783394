export default  {
    accept: "Có",
    reject: "Không",
    choose: "Chọn",
    upload: "Upload",
    cancel: "Hủy",
    dayNames: ["Chủ nhật", "Thứ hai", "Thứ ba", "Thứ tư", "Thứ năm", "Thứ sáu", "Thứ bảy"],
    dayNamesShort: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
    dayNamesMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
    monthNames: ["Tháng Giêng", "Tháng Hai", "Tháng Ba", "Tháng Tư", "Tháng Năm", "Tháng Sáu", "Tháng Bảy", "Tháng Tám", "Tháng Chín", "Tháng Mười", "Tháng Mười một", "Tháng Mười hai"],
    monthNamesShort: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    today: "Hôm nay",
    clear: "Xóa tìm kiếm",
    weekHeader: "Tuần",
    firstDayOfWeek: 1,
    dateFormat: "dd/mm/yy",
    weak: "Yếu",
    medium: "Trung bình",
    strong: "Mạnh",
    passwordPrompt: "Nhập một mật khẩu"
  }