import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  listTraCuuNDBH : [],
  loadingListTraCuuNDBH : false,
  chiTietNDBH : null,
  visiblePopupSelectNDBH : false
}

export const traCuuSlice = createSlice({
  name: 'traCuu',
  initialState,
  reducers: {
    getListTraCuuNDBH(state,action){
      state.loadingListTraCuuNDBH = true
    },
    getListTraCuuNDBHSuccess(state,action){
      state.loadingListTraCuuNDBH = false
      state.listTraCuuNDBH = action.payload
    },
    getListTraCuuNDBHFailed(state,action){
      state.loadingListTraCuuNDBH = false
      state.listTraCuuNDBH = []
    },
    setChiTietNDBH(state,action){
      state.chiTietNDBH = action.payload
    },
    setVisiblePopupSelectNDBH(state,action){
      state.visiblePopupSelectNDBH = action.payload
    },
    unmountNDBH(state){
      state.chiTietNDBH = null
      state.listTraCuuNDBH = []
    }
  },
})
export const traCuuActions = traCuuSlice.actions
const traCuuReducer = traCuuSlice.reducer

export default traCuuReducer

