import { call, put, takeEvery } from "redux-saga/effects";
import { manualAssessmentActions } from "./manualAssessmentSlice";
import { getListWarningManualAssessment } from "./service";
import { handleError } from "_metronic/helpers";

function* handleGetListWarningManualAssessment({payload}) {
  try {
    const resultsListManualAssessment = yield call(getListWarningManualAssessment, payload);
    if(resultsListManualAssessment){
      const resultType = findType(resultsListManualAssessment);
      yield put({
        type: manualAssessmentActions.getListWarningManualAssessmentSuccess.type,
        payload: {
          type :resultType,
          resultsListManualAssessment 
        },
      })
    }
  } catch (e) {
    yield put({
      type: manualAssessmentActions.getListWarningManualAssessmentFailed.type,
    })
    handleError(e)
  }
}

function findType(data) {
  // Kiểm tra xem có bất kỳ phần tử nào với type là 3 hay không
  const hasType3 = data.some(item => item.result === 3);
  // Nếu có phần tử với type là 3, trả về 3
  if (hasType3) {
    return 3;
  } else {
    // Nếu không có phần tử với type là 3, kiểm tra type 2
    const hasType2 = data.some(item => item.result === 2);
    // Nếu có phần tử với type là 2, trả về 2
    if (hasType2) {
      return 2;
    } else {
      // Nếu không có phần tử với type là 2, kiểm tra type 1
      const hasType1 = data.some(item => item.result === 1);
      // Nếu có phần tử với type là 1, trả về 1. Ngược lại, trả về 0.
      return hasType1 ? 1 : null;
    }
  }
}

export default function* manualAssessmentSaga() {
  // yield takeEvery(manualAssessmentActions.getTypeMedicalExpense.type, handleGetTypeMedicalExpense);
  yield takeEvery(manualAssessmentActions.getListWarningManualAssessment.type, handleGetListWarningManualAssessment);
}
