import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  listKhaiThacQuaDaiLy: [],
  listKhaiThacQuaMoiGioi: [],
  listKhaiThacTrucTiep: [],
  listThongTinSanPham: [],
  listLoaiKhachHang: [],
  listLoaiHopDongBaoHiem: [],
  listTinhThanh: [],
  listQuanHuyen: [],
  listXaPhuong: [],
  listPhuongThucBoiThuongBenThuBa: [],
  listChiTietBuocMotHopDong: [],
  loadingChiTietBuocMotHopDong: false,
  idHopDong: '',
  idDonVi: '',
}

export const contractStepOneSlice = createSlice({
  name: 'contractStepOne',
  initialState,
  reducers: {
    // kênh khai thác
    // qua đại lý
    getListKenhKhaiThacQuaDaiLy(state, action) {
      state.listKhaiThacQuaDaiLy = []
    },
    getListKenhKhaiThacQuaDaiLySuccess(state, action) {
      state.listKhaiThacQuaDaiLy = action.payload
    },
    getListKenhKhaiThacQuaDaiLyFailed(state) {
      state.listKhaiThacQuaDaiLy = []
    },
    // qua môi giới
    getListKenhKhaiThacQuaMoiGioi(state, action) {
      state.listKhaiThacQuaMoiGioi = []
    },
    getListKenhKhaiThacQuaMoiGioiSuccess(state, action) {
      state.listKhaiThacQuaMoiGioi = action.payload
    },
    getListKenhKhaiThacQuaMoiGioiFailed(state) {
      state.listKhaiThacQuaMoiGioi = []
    },
    // thông tin sản phẩm
    getListThongTinSanPham(state, action) {
      state.listThongTinSanPham = action.payload
    },
    getListThongTinSanPhamSuccess(state, action) {
      state.listThongTinSanPham = action.payload
    },
    getListThongTinSanPhamFailed(state) {
      state.listThongTinSanPham = []
    },
    // loại khách hàng
    getListLoaiKhachHang(state, action) {
      state.listLoaiKhachHang = action.payload
    },
    getListLoaiKhachHangSuccess(state, action) {
      state.listLoaiKhachHang = action.payload
    },
    getListLoaiKhachHangFailed(state) {
      state.listLoaiKhachHang = []
    },
    // loại hợp đồng
    getListLoaiHopDongBaoHiem(state, action) {
      state.listLoaiHopDongBaoHiem = action.payload
    },
    getListLoaiHopDongBaoHiemSuccess(state, action) {
      state.listLoaiHopDongBaoHiem = action.payload
    },
    getListLoaiHopDongBaoHiemFailed(state) {
      state.listLoaiHopDongBaoHiem = []
    },
    // Phương thức bồi thường bên thứ ba
    getListPhuongThucBoiThuongBenThuBa(state, action) {
      state.listPhuongThucBoiThuongBenThuBa = action.payload
    },
    getListPhuongThucBoiThuongBenThuBaSuccess(state, action) {
      state.listPhuongThucBoiThuongBenThuBa = action.payload
    },
    getListPhuongThucBoiThuongBenThuBaFailed(state) {
      state.listPhuongThucBoiThuongBenThuBa = []
    },
    // id hợp đồng
    setIdHopDong(state, action) {
      state.idHopDong = action.payload
    },
    // danh sach kenh khai thac truc tiep
    getListKenhKhaiThacTrucTiep(state, action) {
    },
    getListKenhKhaiThacTrucTiepSuccess(state, action) {
      state.listKhaiThacTrucTiep = action.payload
    },
    getListKenhKhaiThacTrucTiepFailed(state) {
      state.listKhaiThacTrucTiep = []
    },
    // id đơn vị
    setIdDonVi(state, action) {
      state.idDonVi = action.payload
    },
  },
})
export const contractStepOneActions = contractStepOneSlice.actions
const contractStepOneReducer = contractStepOneSlice.reducer

export default contractStepOneReducer

export const selectListKenhKhaiThacQuaDaiLy = (state) => state.contractStepOne.listKhaiThacQuaDaiLy
export const selectListKenhKhaiThacQuaMoiGioi = (state) =>
  state.contractStepOne.listKhaiThacQuaMoiGioi
export const selectListThongTinSanPham = (state) => state.contractStepOne.listThongTinSanPham
export const selectListLoaiKhachHang = (state) => state.contractStepOne.listLoaiKhachHang
export const selectListLoaiHopDongBaoHiem = (state) => state.contractStepOne.listLoaiHopDongBaoHiem
export const selectListPhuongThucBoiThuongBenThuBa = (state) =>
  state.contractStepOne.listPhuongThucBoiThuongBenThuBa
export const selectDanhSachChiTietBuocMotHopDong = (state) =>
  state.contractStepOne.listChiTietBuocMotHopDong
export const selectLoadingDanhSachChiTietBuocMotHopDong = (state) =>
  state.contractStepOne.loadingChiTietBuocMotHopDong
export const selectIdHopDong = (state) => state.contractStepOne.idHopDong
export const selectKenhKhaiThacTrucTiep = (state) => state.contractStepOne.listKhaiThacTrucTiep
export const selectIdDonVi = (state) => state.contractStepOne.idDonVi
