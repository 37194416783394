/* eslint-disable import/no-anonymous-default-export */
const PREFIX = {
  DANH_MUC: 'danh-muc',
  DUNG_CHUNG: '/dung-chung',
  THAM_DINH_CHU_DONG: 'tham-dinh-chu-dong',
  QUAN_TRI_HE_THONG: 'quan-tri-he-thong',
  TIEN_ICH: 'tien-ich',
  HOP_DONG: 'hop-dong-khach-hang',
  THANH_TOAN: 'thanh-toan',
  DANH_MUC_CHO_HOP_DONG: '/danh-muc-cho-hop-dong',
  BAOCAO: 'thong-ke-bao-cao',
  THAM_DINH_TU_DONG: 'tham-dinh-tu-dong',
  //DUTRU_BAOLANH: 'du-tru-bao-lanh',
  NOTIFICATION: '/notification',
  QUYET_TOAN: 'quyet-toan',
  // Thẩm định chủ động
  HS_BAOLANH_VIENPHI: '/hs-bao-lanh-vien-phi',
  HS_BAOLANH_NOITRU: '/hs-bao-lanh-noi-tru',
  NHAT_KY_HE_THONG: '/nhat-ky-he-thong',
  TRA_CUU: 'tra-cuu'
}

const PATH = {
  DM_QUOC_GIA: '/quoc-gia',
  // quốc gia thiết kế mới
  // DM_QG_THIET_KE_MOI: '/quoc-gia-thiet-ke-moi',
  DM_TINH_THANH: '/tinh-thanh',
  DM_TINH_THANH_THIET_KE_MOI: '/tinh-thanh-thiet-ke-moi',
  DM_QUAN_HUYEN: '/quan-huyen',
  DM_QUAN_HUYEN_THIET_KE_MOI: '/quan-huyen-thiet-ke-moi',
  DM_XA_PHUONG: '/xa-phuong',
  DM_DAN_TOC: '/dan-toc',
  DM_KIEU_BAO_HIEM: '/kieu-bao-hiem',
  DM_KIEU_BAO_HIEM_THIET_KE_MOI: '/kieu-bao-hiem-thiet-ke-moi',
  DM_KIEU_BENH_VIEN: '/kieu-benh-vien',
  DM_KIEU_BENH_VIEN_THIET_KE_MOI: '/kieu-benh-vien-thiet-ke-moi',
  DM_NGHE_NGHIEP: '/nghe-nghiep',
  DM_MA_THUOC: '/ma-thuoc',
  DM_MA_THUOC_THIET_KE_MOI: 'ma-thuoc-thiet-ke-moi',
  DM_MA_BENH: '/ma-benh',
  // mã bệnh thiết kế mói
  DM_MA_BENH_THIET_KE_MOI: '/ma-benh-thiet-ke-moi',
  DM_TRINH_DO: '/trinh-do',
  DM_VAN_TU_Y_TE: '/van-tu-y-te',
  DM_VAT_TU_Y_TE_THIET_KE_MOI: '/vat-tu-y-te-thiet-ke-moi',
  DM_PHAN_NHOM_THUOC: '/phan-nhom-thuoc',
  DM_DUONG_DUNG_THUOC: '/duong-dung-thuoc',
  DM_DUONG_DUNG_THUOC_THIET_KE_MOI: '/duong-dung-thuoc-thiet-ke-moi',
  DM_THUOC_DUOC_CAP_SO_DANG_KI: '/thuoc-duoc-cap-so-dang-ki',
  DM_THUOC_DUOC_CAP_SO_DANG_KI_THIET_KE_MOI: '/thuoc-duoc-cap-so-dang-ki-thiet-ke-moi',
  DM_NHAN_VIEN_BAO_HIEM: '/nhan-vien-bao-hiem',
  DM_DON_VI_TINH: '/don-vi-tinh',
  DM_LOAI_HOP_DONG: '/loai-hop-dong',
  DM_LOAI_KHACH_HANG: '/loai-khach-hang',
  DM_PHAM_VI_DIA_LY: '/pham-vi-dia-ly',
  DM_DIEU_KIEN_CHUNG: '/dieu-kien-chung',
  DM_LOAI_NGUOI_DUOC_BAO_HIEM: '/loai-nguoi-duoc-bao-hiem',
  DM_QUYEN_LOI_BAO_HIEM: '/quyen-loi-bao-hiem',
  DM_SAN_PHAM_BAO_HIEM: '/san-pham-bao-hiem',
  DM_DICH_VU_KY_THUAT: '/dich-vu-ky-thuat',
  DM_DICH_VU_KY_THUAT_THIET_KE_MOI: '/dich-vu-ky-thuat-thiet-ke-moi',
  DM_NGAN_HANG: '/ngan-hang',
  DM_MO_RONG: '/dieu-kien-mo-rong',
  DM_CONG_TY: '/cong-ty',
  DM_XML: '/xml',
  DM_MA_NHOM_CHI_PHI: '/ma-nhom-chi-phi',
  DM_CONG_TY_BAO_HIEM: '/cong-ty-bao-hiem',
  DM_CONG_TY_BAO_HIEM_THIET_KE_MOI: '/cong-ty-bao-hiem-thiet-ke-moi',
  DM_MAKCB: '/ma-kcb',
  DM_VBQD: '/van-bang-quyet-dinh',
  DM_RULE: '/quy-tac',
  DM_REASON: '/ly-do',
  DM_TYPE_XUAT_TOAN: '/loai-xuat-toan',
  DM_QUAN_LY_BAO_HIEM: '/quan-ly-bao-hiem',
  DM_NHOM_QUYENLOI_BAO_HIEM: '/nhom-quyen-loi-bao-hiem-1',
  DM_MA_TAI_NAN: '/ma-tai-nan',
  DM_LOAI_HINH_BAO_HIEM: '/loai-hinh-bao-hiem',
  DM_KHOAN_KHAU_TRU: '/khoan-khau-tru',
  DM_DON_VI_KHAI_THAC_BAO_HIEM: '/don-vi-khai-thac-bao-hiem',
  DM_DIEM_CHAN: '/diem-chan',
  DM_TYPE_FILE: '/loai-file',
  DM_TYPE_RULE: '/kieu-quy-tac',
  DM_MAKCB_THIET_KE_MOI: '/ma-kcb-thiet-ke-moi',
  // Thẩm định chủ động
  TDCD_DSCSKCB: '/danh-sach-cskcb',
  TDCD_DSHSKCB: '/danh-sach-hskcb',
  TDCD_CTHSKCB: '/chi-tiet-hskcb/:id',
  TDCD_DSHSTTTT: '/hs-thanh-toan-truc-tiep',
  TDCD_DSHSBL: '/danh-sach-hs-bao-lanh',
  TDCD_DSHSBL_EDIT: '/danh-sach-hs-bao-lanh/chinh-sua/:id',
  TDCD_HSTTTT_EDIT: '/hs-thanh-toan-truc-tiep/chinh-sua/:id',
  TDCD_HSTTTT_CREATE: '/hs-thanh-toan-truc-tiep/tao-moi',
  TDCD_TMDSHSTTTT: '/them-moi-danh-sach-hs-thanh-toan-truc-tiep',
  HS_DUTRU_DAUVAO: '/hs-du-tru-dau-vao',
  HS_DUTRU_DAUVAO_EDIT: '/hs-du-tru-dau-vao/chinh-sua/:id',
  HS_RAVIEN: '/hs-ra-vien',
  HS_RAVIEN_CREATE: '/hs-ra-vien/tao-moi',
  HS_RAVIEN_EDIT: '/hs-ra-vien/chinh-sua/:id',
  HS_BAOLANH_NGOAITRU: '/hs-bao-lanh-ngoai-tru',
  HS_BAOLANH_NGOAITRU_EDIT: '/hs-bao-lanh-ngoai-tru/chinh-sua/:id',
  HS_BAOLANH_NGOAITRU_CREATE: '/hs-bao-lanh-ngoai-tru/tao-moi',
  HS_HOANTRA: '/hs-hoan-tra',
  HS_DUYET_TREN_PC: '/hs-duyet-tren-phan-cap',
  HS_DUYET_TREN_PC_EDIT: '/hs-duyet-tren-phan-cap/chinh-sua/:id',

  HS_FPT_CARE_STP: '/hs-fpt-care-stp',
  HS_FPT_CARE_STP_CREATE: '/hs-fpt-care-stp/tao-moi',
  HS_FPT_CARE_STP_EDIT: '/hs-fpt-care-stp/chinh-sua/:id',
  HS_HOANTRA_CREATE: '/hs-hoan-tra/tao-moi',
  HS_HOANTRA_EDIT: '/hs-hoan-tra/chinh-sua/:id',
  
  HS_KCB: '/hs-kcb',
  HS_KCB_EDIT: '/hs-kcb/chinh-sua/:id',
  //
  QTHT_PERMISSION: '/permissions',
  QTHT_ROLES: '/chuc-danh',
  QTHT_USERS: '/nguoi-dung',
  QTHT_USERS_HISTORY: '/lich-su',
  QTHT_PERMISSION_MANAGEMENT: '/quan-li-tac-vu',
  QTHT_PERMISSION_GROUP_MANAGEMENT: '/quan-li-nhom-quyen',
  TI_TAI_LIEU_HE_THONG: '/tai-lieu-hdsd',
  QTHT_QUAN_LI_BO_NHO_DEM: '/quan-li-bo-nho-dem',
  QTHT_THONG_SO_HE_THONG: '/thong-so-he-thong',
  HOP_DONG: '/danh-sach',
  TAI_KHOAN_CHI_TRA :'/tai-khoan-chi-tra',
  TAO_HOP_DONG: '/them-moi/thong-tin-khach-hang',
  TAO_HOP_DONG_STEP_2: '/them-moi/thong-tin-chi-phi-bao-hiem/:id',
  CHI_TIET_HOP_DONG: '/chi-tiet-hop-dong/:id',
  QTHT_USER_PERMISSON: '/quan-ly-nhom-quyen',
  QTHT_USER_PERMISSON_EDIT: '/quan-ly-nhom-quyen/nguoi-dung/chinh-sua/:id',
  QTHT_NOTIFICATION_TEMPLATE: '/notification-template',
  QTHT_NOTIFICATION_TYPE: '/notification-type',
  QTHT_EMAIL_SMTP: '/email-smtp',
  QTHT_EMAIL_API: '/email-api',
  QTHT_SMS: '/sms',
  QTHT_CLIENT: '/client',
  QTHT_NOTIFICATION_HISTORY: '/notification-history',
  QTHT_DELIVERY_LOG: '/delivery-log',
  QTHT_NHAT_KY_HE_THONG: '/nhat-ky-he-thong',

  TDCD_DTBL: '/du-tru-bao-lanh',
  TDCD_DTBL_DUYETCHIPHI: '/du-tru-bao-lanh/chinh-sua/:id',

  THANHTOAN_DANHSACHHOSOTHANHTOAN: '/danh-sach-ho-so-thanh-toan',
  THANHTOAN_DANHSACHHOSOTHANHTOAN_VER2: '/danh-sach-hs-thanh-toan-version-2',

  THANHTOAN_DANHSACHDENGHIDUYETTHANHTOAN: '/danh-sach-de-nghi-duyet-thanh-toan',
  THANHTOAN_CHITIETDENGHIDUYETTHANHTOAN: '/danh-sach-de-nghi-duyet-thanh-toan/chi-tiet/:id',

  THANHTOAN_DANHSACHPHIEUDENGHITHANHTOAN_VER2: '/danh-sach-phieu-de-nghi-thanh-toan-version-2',
  THANHTOAN_CHITIETDENGHIDUYETTHANHTOAN_VER2:
    '/danh-sach-phieu-de-nghi-thanh-toan-version-2/chi-tiet/:id',
  THANHTOAN_TRUYENNHANDULIEU:
    '/truyen-nhan-du-lieu',

  CAUHINH_PHEDUYET_DENGHI_THANHTOAN: '/cau-hinh-phe-duyet-de-nghi-thanh-toan',
  CAUHINH_LOAIFILE_CHO_HOPDONG: '/cau-hinh-loai-file-cho-hop-dong',

  //BAO CAO
  BAOCAO_KHACHHANG: '/bao-cao-boi-thuong/bao-cao-khach-hang',
  BAOCAO_HOPDONG: '/bao-cao-boi-thuong/bao-cao-hop-dong',
  BAOCAO_SANPHAM: '/bao-cao-boi-thuong/bao-cao-san-pham',
  BAOCAO_MABENH: '/bao-cao-mo-hinh-benh-tat/bao-cao-ma-benh',
  BAOCAO_MHBT_CSKCB: '/bao-cao-mo-hinh-benh-tat/co-so-kham-chua-benh',
  BAOCAO_LOAIHINHBOITHUONG: '/bao-cao-boi-thuong/bao-cao-loai-hinh-boi-thuong',
  BAOCAO_QUYENLOIBAOHIEM: '/bao-cao-boi-thuong/bao-cao-quyen-loi-bao-hiem',
  BAOCAO_MOHINHBENHTAT: '/mo-hinh-benh-tat',
  BAOCAO_DANHSACHHOSO: '/bao-cao-ho-so/danh-sach-ho-so',
  BAOCAO_THONGKETAIKHOAN: '/thong-ke-tai-khoan',

  DASHBOARD: '/dashboard',
  DASHBOARD_FULLCREEN: '/dashboard-fullscreen',
  //THAM DINH TU DONG
  QUY_TAC_THAM_DINH: '/quy-tac-tham-dinh',
  DANH_SACH_QUY_TAC_THAM_DINH: '/danh-sach-quy-tac-tham-dinh',
  //TONG HOP BAO CAO
  TONG_HOP_BAO_CAO: '/tong-hop-bao-cao',
  //TRA CUU
  TRA_CU_NDBH: '/tra-cuu-ndbh',
  CHI_TIET_TRA_CUU_NDBH: '/chi-tiet-ho-so-tra-cuu'
}

const FULL_PATH = {
  // Danh mục
  DM_QUOC_GIA: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_QUOC_GIA,
  // danh mục quốc gia thiết kế mới
  DM_QG_THIET_KE_MOI: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_QG_THIET_KE_MOI,
  DM_TINH_THANH: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_TINH_THANH,
  DM_TINH_THANH_THIET_KE_MOI: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_TINH_THANH_THIET_KE_MOI,
  DM_QUAN_HUYEN: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_QUAN_HUYEN,
  DM_QUAN_HUYEN_THIET_KE_MOI: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_QUAN_HUYEN_THIET_KE_MOI,
  DM_XA_PHUONG: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_XA_PHUONG,
  DM_DAN_TOC: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_DAN_TOC,
  DM_KIEU_BAO_HIEM: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_KIEU_BAO_HIEM,
  DM_KIEU_BAO_HIEM_THIET_KE_MOI:
    PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_KIEU_BAO_HIEM_THIET_KE_MOI,
  DM_KIEU_BENH_VIEN: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_KIEU_BENH_VIEN,
  DM_KIEU_BENH_VIEN_THIET_KE_MOI:
    PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_KIEU_BENH_VIEN_THIET_KE_MOI,

  DM_NGHE_NGHIEP: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_NGHE_NGHIEP,
  DM_MA_THUOC: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_MA_THUOC,
  DM_MA_THUOC_THIET_KE_MOI: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_MA_THUOC_THIET_KE_MOI,
  DM_MA_BENH: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_MA_BENH,
  DM_MA_BENH_THIET_KE_MOI: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_MA_BENH_THIET_KE_MOI,
  DM_TRINH_DO: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_TRINH_DO,
  DM_VAN_TU_Y_TE: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_VAN_TU_Y_TE,
  DM_VAT_TU_Y_TE_THIET_KE_MOI:
    PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_VAT_TU_Y_TE_THIET_KE_MOI,
  DM_PHAN_NHOM_THUOC: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_PHAN_NHOM_THUOC,
  DM_DUONG_DUNG_THUOC: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_DUONG_DUNG_THUOC,
  DM_DUONG_DUNG_THUOC_THIET_KE_MOI:
    PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_DUONG_DUNG_THUOC_THIET_KE_MOI,
  DM_DICH_VU_KY_THUAT: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_DICH_VU_KY_THUAT,
  DM_DICH_VU_KY_THUAT_THIET_KE_MOI:
    PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_DICH_VU_KY_THUAT_THIET_KE_MOI,
  DM_XML: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_XML,
  DM_MA_NHOM_CHI_PHI: PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_MA_NHOM_CHI_PHI,
  DM_CONG_TY_BAO_HIEM: PREFIX.DANH_MUC + PATH.DM_CONG_TY_BAO_HIEM,
  DM_CONG_TY_BAO_HIEM_THIET_KE_MOI: PREFIX.DANH_MUC + PATH.DM_CONG_TY_BAO_HIEM_THIET_KE_MOI,
  DM_THUOC_DUOC_CAP_SO_DANG_KI:
    PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_THUOC_DUOC_CAP_SO_DANG_KI,
  DM_THUOC_DUOC_CAP_SO_DANG_KI_THIET_KE_MOI:
    PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_THUOC_DUOC_CAP_SO_DANG_KI_THIET_KE_MOI,
  DM_MA_KCB: PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_MAKCB,
  DM_MAKCB_THIET_KE_MOI: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_MAKCB_THIET_KE_MOI,
  DM_VBQD: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_VBQD,
  DM_RULE: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_RULE,
  DM_REASON: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_REASON,
  DM_TYPE_XUAT_TOAN: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_TYPE_XUAT_TOAN,
  DM_TYPE_FILE: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_TYPE_FILE,
  DM_TYPE_RULE: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_TYPE_RULE,
  DM_QUAN_LY_BAO_HIEM: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_QUAN_LY_BAO_HIEM,
  DM_NHOM_QUYENLOI_BAO_HIEM: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_NHOM_QUYENLOI_BAO_HIEM,
  DM_MA_TAI_NAN: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_MA_TAI_NAN,
  DM_LOAI_HINH_BAO_HIEM: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_LOAI_HINH_BAO_HIEM,
  DM_KHOAN_KHAU_TRU: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_KHOAN_KHAU_TRU,
  DM_DON_VI_KHAI_THAC_BAO_HIEM:
    PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_DON_VI_KHAI_THAC_BAO_HIEM,
  DM_DIEM_CHAN: PREFIX.DANH_MUC + PREFIX.DUNG_CHUNG + PATH.DM_DIEM_CHAN,
  DM_NHAN_VIEN_BAO_HIEM:
    PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_NHAN_VIEN_BAO_HIEM,
  DM_DON_VI_TINH: PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_DON_VI_TINH,
  DM_LOAI_HOP_DONG: PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_LOAI_HOP_DONG,
  DM_LOAI_KHACH_HANG: PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_LOAI_KHACH_HANG,
  DM_PHAM_VI_DIA_LY: PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_PHAM_VI_DIA_LY,
  DM_DIEU_KIEN_CHUNG: PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_DIEU_KIEN_CHUNG,
  DM_LOAI_NGUOI_DUOC_BAO_HIEM:
    PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_LOAI_NGUOI_DUOC_BAO_HIEM,
  DM_QUYEN_LOI_BAO_HIEM:
    PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_QUYEN_LOI_BAO_HIEM,
  DM_SAN_PHAM_BAO_HIEM: PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_SAN_PHAM_BAO_HIEM,
  DM_NGAN_HANG: PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_NGAN_HANG,
  DM_MO_RONG: PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_MO_RONG,
  DM_CONG_TY: PREFIX.DANH_MUC + PREFIX.DANH_MUC_CHO_HOP_DONG + PATH.DM_CONG_TY,
  DM_CAUHINH_PHEDUYET_DENGHI_THANHTOAN: PREFIX.DANH_MUC + PATH.CAUHINH_PHEDUYET_DENGHI_THANHTOAN,
  DM_CAUHINH_LOAIFILE_CHO_HOPDONG: PREFIX.DANH_MUC + PATH.CAUHINH_LOAIFILE_CHO_HOPDONG,

  // Thẩm định chủ động
  TDCD_DSCSKCB: PREFIX.DANH_MUC + PATH.TDCD_DSCSKCB,
  TDCD_DSHSKCB: PREFIX.THAM_DINH_CHU_DONG + PATH.TDCD_DSHSKCB,
  TDCD_CTHSKCB: PREFIX.THAM_DINH_CHU_DONG + PATH.TDCD_CTHSKCB,
  TDCD_DSHSTTTT: PREFIX.THAM_DINH_CHU_DONG + PATH.TDCD_DSHSTTTT,
  TDCD_DSHSBL: PREFIX.THAM_DINH_CHU_DONG + PATH.TDCD_DSHSBL,
  TDCD_TM_DSHSTTTT: PREFIX.THAM_DINH_CHU_DONG + PATH.TDCD_TMDSHSTTTT,
  TDCD_DTBL_TT: PREFIX.THAM_DINH_CHU_DONG + PATH.TDCD_DTBL,
  HS_DUTRU_DAUVAO:
    PREFIX.THAM_DINH_CHU_DONG +
    PREFIX.HS_BAOLANH_VIENPHI +
    PREFIX.HS_BAOLANH_NOITRU +
    PATH.HS_DUTRU_DAUVAO,
  HS_RAVIEN:
    PREFIX.THAM_DINH_CHU_DONG +
    PREFIX.HS_BAOLANH_VIENPHI +
    PREFIX.HS_BAOLANH_NOITRU +
    PATH.HS_RAVIEN,
  HS_BAOLANH_NGOAITRU:
    PREFIX.THAM_DINH_CHU_DONG + PREFIX.HS_BAOLANH_VIENPHI + PATH.HS_BAOLANH_NGOAITRU,
  HS_HOAN_TRA: PREFIX.THAM_DINH_CHU_DONG + PATH.HS_HOANTRA,
  HS_DUYET_TREN_PC: PREFIX.THAM_DINH_CHU_DONG + PATH.HS_DUYET_TREN_PC,

  HS_FPT_CARE_STP: PREFIX.THAM_DINH_CHU_DONG + PATH.HS_FPT_CARE_STP,
  HS_KCB: PREFIX.THAM_DINH_CHU_DONG + PATH.HS_KCB,
  // Quản trị hệ thống
  QTHT_PERMISSION: PREFIX.QUAN_TRI_HE_THONG + PATH.QTHT_PERMISSION,
  QTHT_ROLES: PREFIX.QUAN_TRI_HE_THONG + PATH.QTHT_ROLES,
  QTHT_USERS: PREFIX.QUAN_TRI_HE_THONG + PATH.QTHT_USERS,
  QTHT_USERS_HISTORY: PREFIX.QUAN_TRI_HE_THONG + PATH.QTHT_USERS_HISTORY,
  QTHT_PERMISSION_MANAGEMENT: PREFIX.QUAN_TRI_HE_THONG + PATH.QTHT_PERMISSION_MANAGEMENT,
  QTHT_PERMISSION_GROUP_MANAGEMENT:
    PREFIX.QUAN_TRI_HE_THONG + PATH.QTHT_PERMISSION_GROUP_MANAGEMENT,
  QTHT_QUAN_LI_BO_NHO_DEM: PREFIX.QUAN_TRI_HE_THONG + PATH.QTHT_QUAN_LI_BO_NHO_DEM,
  QTHT_THONG_SO_HE_THONG: PREFIX.QUAN_TRI_HE_THONG + PATH.QTHT_THONG_SO_HE_THONG,
  QTHT_USER_PERMISSION: PREFIX.QUAN_TRI_HE_THONG + PATH.QTHT_USER_PERMISSON,
  // QTHT > NOTIFICATION
  QTHT_NOTIFICATION_TEMPLATE:
    PREFIX.QUAN_TRI_HE_THONG + PREFIX.NOTIFICATION + PATH.QTHT_NOTIFICATION_TEMPLATE,
  QTHT_NOTIFICATION_TYPE:
    PREFIX.QUAN_TRI_HE_THONG + PREFIX.NOTIFICATION + PATH.QTHT_NOTIFICATION_TYPE,
  QTHT_CLIENT: PREFIX.QUAN_TRI_HE_THONG + PREFIX.NOTIFICATION + PATH.QTHT_CLIENT,
  QTHT_EMAIL_SMTP: PREFIX.QUAN_TRI_HE_THONG + PREFIX.NOTIFICATION + PATH.QTHT_EMAIL_SMTP,
  QTHT_EMAIL_API: PREFIX.QUAN_TRI_HE_THONG + PREFIX.NOTIFICATION + PATH.QTHT_EMAIL_API,
  QTHT_SMS: PREFIX.QUAN_TRI_HE_THONG + PREFIX.NOTIFICATION + PATH.QTHT_SMS,
  QTHT_NOTIFICATION_HISTORY:
    PREFIX.QUAN_TRI_HE_THONG + PREFIX.NOTIFICATION + PATH.QTHT_NOTIFICATION_HISTORY,
  QTHT_DELIVERY_LOG: PREFIX.QUAN_TRI_HE_THONG + PREFIX.NOTIFICATION + PATH.QTHT_DELIVERY_LOG,
  QTHT_NHAT_KY_HE_THONG: PREFIX.QUAN_TRI_HE_THONG + PATH.QTHT_NHAT_KY_HE_THONG,

  // Tiện ích
  TI_TAI_LIEU_HE_THONG: PREFIX.TIEN_ICH + PATH.TI_TAI_LIEU_HE_THONG,
  // Hợp đồng
  HOP_DONG: PREFIX.HOP_DONG + PATH.HOP_DONG,
  TAI_KHOAN_CHI_TRA: PREFIX.HOP_DONG + PATH.TAI_KHOAN_CHI_TRA,
  TAO_HOP_DONG: PREFIX.HOP_DONG + PATH.TAO_HOP_DONG,
  TAO_HOP_DONG_STEP_2: PREFIX.HOP_DONG + PATH.TAO_HOP_DONG_STEP_2,
  CHI_TIET_HOP_DONG: PREFIX.HOP_DONG + PATH.CHI_TIET_HOP_DONG,

  // thanh toán
  THANHTOAN_DANHSACHDENGHIDUYETTHANHTOAN:
    PREFIX.THANH_TOAN + PATH.THANHTOAN_DANHSACHDENGHIDUYETTHANHTOAN,
  THANHTOAN_DANHSACHHOSOTHANHTOAN: PREFIX.THANH_TOAN + PATH.THANHTOAN_DANHSACHHOSOTHANHTOAN,
  THANHTOAN_DANHSACHHOSOTHANHTOAN_VER2:
    PREFIX.THANH_TOAN + PATH.THANHTOAN_DANHSACHHOSOTHANHTOAN_VER2,
  THANHTOAN_DANHSACHPHIEUDENGHITHANHTOAN_VER2:
    PREFIX.THANH_TOAN + PATH.THANHTOAN_DANHSACHPHIEUDENGHITHANHTOAN_VER2,

  THANHTOAN_CHITIETDENGHIDUYETTHANHTOAN:
    PREFIX.THANH_TOAN + PATH.THANHTOAN_CHITIETDENGHIDUYETTHANHTOAN,
  
    THANHTOAN_TRUYENNHANDULIEU:
    PREFIX.THANH_TOAN + PATH.THANHTOAN_TRUYENNHANDULIEU,  

  // báo cáo
  DASHBOARD: PREFIX.BAOCAO + PATH.DASHBOARD,
  DASHBOARD_FULLSCREEN: PATH.DASHBOARD_FULLCREEN,
  BAOCAO_KHACHHANG: PREFIX.BAOCAO + PATH.BAOCAO_KHACHHANG,
  BAOCAO_HOPDONG: PREFIX.BAOCAO + PATH.BAOCAO_HOPDONG,
  BAOCAO_SANPHAM: PREFIX.BAOCAO + PATH.BAOCAO_SANPHAM,
  BAOCAO_MABENH: PREFIX.BAOCAO + PATH.BAOCAO_MABENH,
  BAOCAO_MHBT_CSKCB: PREFIX.BAOCAO + PATH.BAOCAO_MHBT_CSKCB,
  BAOCAO_LOAIHINHBOITHUONG: PREFIX.BAOCAO + PATH.BAOCAO_LOAIHINHBOITHUONG,
  BAOCAO_QUYENLOIBAOHIEM: PREFIX.BAOCAO + PATH.BAOCAO_QUYENLOIBAOHIEM,
  BAOCAO_DANHSACHHOSO: PREFIX.BAOCAO + PATH.BAOCAO_DANHSACHHOSO,
  BAOCAO_THONGKETAIKHOAN: PREFIX.BAOCAO + PATH.BAOCAO_THONGKETAIKHOAN,


  // Thẩm định tự đồng
  TDTD_QUYTAC_TUDONG: PREFIX.THAM_DINH_TU_DONG + PATH.QUY_TAC_THAM_DINH,
  TDTD_DANHSACH_QUYTAC_TUDONG: PREFIX.THAM_DINH_TU_DONG + PATH.DANH_SACH_QUY_TAC_THAM_DINH,

  // Quyết toán
  BAO_CAO: PREFIX.THANH_TOAN + PATH.TONG_HOP_BAO_CAO,

  // Tra cứu
  TRA_CUU: PREFIX.TRA_CUU
}

export { FULL_PATH, PATH, PREFIX }

