import { all } from "redux-saga/effects";
import commonSaga from "./common/commonSaga";
import searchFilterSaga from "../modules/proactive-appraisal/redux/searchFilter/searchFilterSaga";
import categorySaga from "../modules/category/redux/category/categorySaga";
import permissionsSaga from "./permissions/permissionsSaga";
import contractSaga from "../modules/contract-customer/redux/contract/contractSaga";
import listContractSaga from "../modules/contract-customer/redux/listContract/listContractSaga";
import contractStepThirdSaga from "../modules/contract-customer/redux/contractStepThird/contractStepThirdSaga";
import contractStepTwoSaga from "../modules/contract-customer/redux/contractStepTwo/contractStepTwoSaga";
import contractStepOneSaga from "../modules/contract-customer/redux/contractStepOne/contractStepOneSaga";
import manualAssessmentSaga from "../modules/proactive-appraisal/redux/manualAssessment/manualAssessmentSaga";
import phieuDeNghiSaga from "app/modules/payment/redux/phieuDeNghi/phieuDeNghiSaga";
import traCuuSaga from "app/modules/tra-cuu/redux/traCuu/traCuuSaga";

export default function* rootSaga() {
  yield all([
    commonSaga(),
    searchFilterSaga(),
    categorySaga(),
    permissionsSaga(),
    contractSaga(),
    listContractSaga(),
    contractStepThirdSaga(),
    contractStepTwoSaga(),
    contractStepOneSaga(),
    manualAssessmentSaga(),
    phieuDeNghiSaga(),
    traCuuSaga()
  ]);
}
