import { createRoot } from 'react-dom/client'
// Axios
import { QueryClient, QueryClientProvider } from 'react-query'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider } from './app/pages/auth'
import { Provider } from 'react-redux'
import store, { persistor } from "./app/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { locale, addLocale } from 'primereact/api';
import  { registerLocale,setDefaultLocale } from "react-datepicker";
import {default as viLanguge} from './app/components/language/vi'
import vi from 'date-fns/locale/vi';

//Tiếng việt cho primeReact
addLocale('vi',viLanguge);
locale('vi');

//Tiếng việt cho datePicker
registerLocale('vi', vi)
setDefaultLocale('vi');
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
if (process.env.NODE_ENV === "test") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <AppRoutes />
            </PersistGate>
          </Provider>
        </AuthProvider>
      </MetronicI18nProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  )
}
