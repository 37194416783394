import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {useLayout} from '../../core'
import {MutableRefObject, useEffect, useRef} from 'react'
import {ToggleComponent} from '../../../assets/ts/components'
import {SidebarMenuMain} from './sidebar-menu/SidebarMenuMain'

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
}

const SidebarLogo = (props: PropsType) => {
  const {config} = useLayout()
  const toggleRef = useRef<HTMLDivElement>(null)
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default
  useEffect(() => {
    setTimeout(() => {
      const toggleObj = ToggleComponent.getInstance(toggleRef.current!) as ToggleComponent | null
      if (toggleObj === null) {
        return
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on('kt.toggle.change', function () {
        console.log(
          'config.app?.sidebar?.default?.minimize?.desktop?.default',
          appSidebarDefaultMinimizeDefault
        )

        // Set animation state
        props.sidebarRef.current!.classList.add('animating')
        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current!.classList.remove('animating')
        }, 300)
      })
    }, 600)
  }, [toggleRef, props.sidebarRef])

  return (
    <>
      <div style={{backgroundColor: '#f2f5f8',height:'816px'}} className='app-sidebar-menu  flex-column-fluid'>
        <div
          id='kt_app_sidebar_menu_wrapper'
          className='app-sidebar-wrapper hover-scroll-overlay-y my-5'
          data-kt-scroll='true'
          data-kt-scroll-activate='true'
          data-kt-scroll-height='auto'
          data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
          data-kt-scroll-wrappers='#kt_app_sidebar_menu'
          data-kt-scroll-offset='5px'
          data-kt-scroll-save-state='true'
          style={{maxHeight:'90vh' , minHeight:'90vh'}}
        >
          <div
            className='menu menu-column menu-rounded menu-sub-indention px-3'
            id='#kt_app_sidebar_menu'
            data-kt-menu='true'
            data-kt-menu-expand='false'
          >
            <SidebarMenuMain />
          </div>
        </div>
        {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
          <div
            style={{top: 70}}
            ref={toggleRef}
            id='kt_app_sidebar_toggle'
            className={clsx(
              'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute  start-100 translate-middle rotate',
              {active: appSidebarDefaultMinimizeDefault}
            )}
            data-kt-toggle='true'
            data-kt-toggle-state={toggleState}
            data-kt-toggle-target='body'
            data-kt-toggle-name={`app-sidebar-${toggleType}`}
          >
            <KTIcon iconName='double-left' className='fs-2 rotate-180' />
          </div>
        )}
      </div>
    </>
  )
}

export {SidebarLogo}
